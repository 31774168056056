import { useEditRideDetailsStore } from "@/stores/edit-ride-details";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";
import UpdateRideDetailsForm from "@/components/update-ride-details-dialog/update-ride-details-form.tsx";
import { UpdatableRideData } from "@/typings";


/**
 * Type defs of props for UpdateRideDetailsDialog
 */
interface UpdateRideDetailsDialogProps {
    /**
     * Optional callback function which will be called after ride data is updated
     * @param rideId Id of ride
     * @param updatedRideData Updated ride data
     */
    onSuccess?: (rideId: string, updatedRideData: Partial<UpdatableRideData>) => void
}


/**
 * Dialog component which shows edit form for the ride 
 */
const UpdateRideDetailsDialog = ({ onSuccess }: UpdateRideDetailsDialogProps) => {


    const isEditDialogOpen = useEditRideDetailsStore((state) => {
        return state.isEditDialogOpen
    })

    const storedRideData = useEditRideDetailsStore((state) => state.rideDetails)

    const closeEditDialog = useEditRideDetailsStore((state) => state.closeEditDialog);


    const handleDialogStateChange = () => {
        closeEditDialog();
    }
    return (

        <Dialog open={isEditDialogOpen} onOpenChange={handleDialogStateChange}>
            <DialogContent aria-describedby={undefined} className="max-w-xl">
                <DialogTitle>Update Ride Details</DialogTitle>
                <UpdateRideDetailsForm
                    isOpen={isEditDialogOpen}
                    storedRideData={storedRideData}
                    onSuccess={onSuccess}
                    onClose={closeEditDialog}
                />
            </DialogContent>
        </Dialog>
    )
}


export default UpdateRideDetailsDialog;