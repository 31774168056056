import UserSecuritySettingsView from "@/components/user-security-settings-view";

/**
 * Component which shows user security in settings view
 */
const UserSecurityView = () => {
    return (
        <UserSecuritySettingsView />
    )
}


export default UserSecurityView;