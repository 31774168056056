import PulseLoader from 'react-spinners/PulseLoader';
import styles from './Loader.module.css';

import { cn } from '@/lib/utils';

/**
 * Type definitions for props of Loader component
 */
export interface LoaderProps {
  className?: string;
  type?: "spinner" | "pulse",
  /**
   * prop for pulse loader
   */
  color?: string,
  /**
   * Prop for pulse loader
   */
  size?: string | number,
}

/**
 * Component which displays a loading animation
 */
const Loader = ({ className, type = "pulse", color = "hsl(var(--primary))", size }: LoaderProps) => {
  return (

    type === "spinner" ?

      <div
        className={cn(
          styles.loader,
          'h-full w-full border-4 border-b-primary',
          className
        )}
      ></div>
      :

      <PulseLoader className={className} color={color} size={size} />

  );
};

export default Loader;
