import Swal, { SweetAlertOptions } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const WrappedSwal = withReactContent(Swal);


/**
 * Default sweet alert config
 */
const defaultAlertOpts = {
  iconColor: "hsl(var(--primary))",
  confirmButtonText: "Yes",
  cancelButtonText: "Cancel",
  cancelButtonColor: "hsl(var(--destructive))",
  confirmButtonColor: "hsl(var(--primary))"

}


/**
 * Show sweet alert 
 * @param options Sweet alert config options
 */
export const showSweetAlert = async (options: SweetAlertOptions) => {
  return await WrappedSwal.fire({ ...defaultAlertOpts, ...options });
};
export default WrappedSwal;
