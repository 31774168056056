import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { buttonVariants } from '@/components/custom/button'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'

interface SettingsSidebarProps extends React.HTMLAttributes<HTMLElement> {
    items: {
        path: string
        title: string
        icon: JSX.Element
    }[]
}

/**
 * Component which shows sidebar for the settings view
 */
const SettingsSidebar = ({
    className,
    items,
    ...props
}: SettingsSidebarProps) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [val, setVal] = useState(pathname ?? '/settings')

    const handleSelect = (e: string) => {
        setVal(e)
        navigate(e)
    }

    return (
        <>
            <div className='p-1 md:hidden'>
                <Select value={val} onValueChange={handleSelect}>
                    <SelectTrigger className='h-12 sm:w-48'>
                        <SelectValue placeholder='Theme' />
                    </SelectTrigger>
                    <SelectContent>
                        {items.map((item) => (
                            <SelectItem key={item.path} value={item.path}>
                                <div className='flex gap-x-4 px-2 py-1'>
                                    <span className='scale-125'>{item.icon}</span>
                                    <span className='text-md'>{item.title}</span>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            <div className='hidden w-full overflow-x-auto bg-background px-1 py-2 md:block'>
                <nav
                    className={cn(
                        'flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1',
                        className
                    )}
                    {...props}
                >
                    {items.map((item) => (
                        <Link
                            key={item.path}
                            to={item.path}
                            className={cn(
                                buttonVariants({ variant: 'ghost' }),
                                pathname === item.path
                                    ? 'bg-muted hover:bg-muted text-primary hover:text-primary'
                                    : 'hover:bg-muted',
                                'justify-start'
                            )}
                        >
                            <span className='mr-2'>{item.icon}</span>
                            {item.title}
                        </Link>
                    ))}
                </nav>
            </div>
        </>
    )
}


export default SettingsSidebar