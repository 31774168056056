
import ContentSection from "@/components/content-section";
import { Button } from "../ui/button";
import { useState } from "react";
import ChangePasswordDialog from "../change-password-dialog";



/**
 * Component which shows user security settings
 */
const UserSecuritySettingsView = () => {
    const [isChangePasswordDialogOpen, setChangePasswordDialogOpen] = useState<boolean>(false);



    return (

        <>

            <ContentSection
                title='Security'
                desc='You can manage your account security here'
            >
                <div className="">
                    <p className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Password
                    </p>

                    <Button className="mt-3" onClick={() => {
                        setChangePasswordDialogOpen(true)
                    }}>

                        Change Password
                    </Button>
                </div>


            </ContentSection>

            <ChangePasswordDialog isOpen={isChangePasswordDialogOpen} onOpenChange={setChangePasswordDialogOpen} />
        </>

    )
}



export default UserSecuritySettingsView;