
import RidesDashboard from "@/components/rides-dashboard"
import ViewHeading from "@/components/view-heading";

/**
 * Component which shows rides dashboard view
 */
const RidesView = () => {
    return (
        <>
            <div className="flex size-full flex-col">
                <div className="mb-2">
                    <ViewHeading title="Rides" subtitle="Manage your rides here" />
                </div>
                <RidesDashboard />

            </div>


        </>


    )
}

export default RidesView;