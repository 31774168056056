

import * as React from "react"
import { formatDate } from "@/lib/helpers/datetime"
import { Calendar as CalendarIcon, XIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { DateRange } from "react-day-picker";


/**
 * Type defs for DateRangerPicker props
 */
interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Date Range object
     */
    dateRange: DateRange,
    /**
     * You can pass custom classes using this prop
     * 
     */
    className?: string,
    /**
     * Callback to handle date range change
     * @param dateRange Date Range Object
     */
    onDateRangeSelect: (dateRange?: DateRange) => void,
    /**
     * Optional Callback which will be called when clear btn is pressed
     */
    onClear?: () => void
}



/**
 * Component which allows selecting date range or a single date
 */
export function DatePickerWithRange({
    className,
    dateRange,
    onDateRangeSelect,
    onClear
}: DatePickerWithRangeProps) {


    /**
     * Clears date range
     * @param e Click event data on SVG element
     */
    const handleClickOnClearIcon = (e: React.MouseEvent<SVGSVGElement>) => {

        e.stopPropagation()
        onDateRangeSelect(undefined);
        onClear?.()
    }




    /**
     * Handle Date Range change
     * @param dateRange Date Range Object
     */
    const handleDateRangeChange = (dateRange?: DateRange) => {
        onDateRangeSelect(dateRange)
    }


    return (
        <div className={cn("grid gap-2", className)}>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant={"outline"}
                        className={cn(
                            "w-[300px] justify-start text-left font-normal",
                            !dateRange && "text-muted-foreground"
                        )}
                    >

                        <div className="flex min-w-0 flex-1 items-center">
                            <CalendarIcon className="mr-2 size-4" />
                            {dateRange?.from ? (

                                <>
                                    {dateRange.to ? (
                                        <>
                                            {formatDate(dateRange.from, "LLL dd, y", true)} -{" "}
                                            {formatDate(dateRange.to, "LLL dd, y", true)}
                                        </>
                                    ) : (
                                        formatDate(dateRange.from, "LLL dd, y", true)
                                    )}



                                </>



                            ) : (
                                <span>Pick a date range or single date</span>
                            )}

                        </div>

                        <div>
                            {
                                dateRange ?

                                    <XIcon onClick={handleClickOnClearIcon} />
                                    : null
                            }

                        </div>


                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        selected={dateRange}
                        onSelect={handleDateRangeChange}
                        numberOfMonths={2}
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
}

