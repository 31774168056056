import { create } from "zustand";

/**
 * Type defs for Sidebar Store
 */
interface SidebarStore {
  isMinimized: boolean;
  toggle: () => void;
}

/**
 * Custom hook created with zustand
 */
export const useSidebar = create<SidebarStore>((set) => ({
  isMinimized: false,
  toggle: () => set((state) => ({ isMinimized: !state.isMinimized })),
}));
