/* eslint-disable @typescript-eslint/no-misused-promises */

import { Button } from "@/components/custom/button";
import { Input } from "@/components/ui/input";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { VerificationFlow } from "@ory/kratos-client";
import { z } from "zod"
import { AxiosError } from "axios";
import { extractCsrfTokenFromOryResponse, extractErrorMessageFromOryResponse, } from "@/lib/ory";
import { submitAccountVerificationCode } from "@/lib/helpers/user";
import { RawAccountVerificationData } from "@/typings";
// import Loader from "./Loader/Loader";
import useLoader from "@/lib/hooks/use-loader";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast";

/**
 * Type defs for AccountVerificationForm 
 */
export interface AccountRecoveryFormProps {
    /**
     * Verification flow data 
     */
    flowData: VerificationFlow,
    /**
     * Optional callback function which is called when verification is successful
     */
    onSuccess?: () => void
}

/**
 * Account form schema 
 */
const formSchema = z.object({
    code: z.string().min(1, "Code is required"),

})

/**
 * Form component for Account Recovery view 
 */
const AccountVerificationForm = ({ flowData, onSuccess }: AccountRecoveryFormProps) => {


    const { isLoaderVisible, setLoaderVisible } = useLoader(false)

    // Form created via react-hook-form
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            code: "",
        },

    })


    /**
     * This function is called when form is submitted 
     * @param values Form values
     */
    async function onSubmit(values: z.infer<typeof formSchema>) {

        try {


            const csrfToken = extractCsrfTokenFromOryResponse(flowData);



            setLoaderVisible(true)
            const accountVerificationData: RawAccountVerificationData = {
                code: values.code,
                csrfToken
            }


            const res = await submitAccountVerificationCode(accountVerificationData, flowData.id)

            const errorMsg = extractErrorMessageFromOryResponse(res);


            if (errorMsg) {
                showErrorToast(errorMsg);
                return;
            }


            showSuccessToast("Account verification completed")
            onSuccess?.()

        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE
            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as VerificationFlow;
                errorMessage = extractErrorMessageFromOryResponse(errorResponse);
            }
            showErrorToast(errorMessage)

        } finally {
            setLoaderVisible(false)
        }

    }





    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">


                <div className='mb-2 flex flex-col space-y-2 text-left'>

                    <p className='text-sm text-muted-foreground'>
                        Enter the verification code sent to your email
                    </p>
                </div>


                <FormField

                    control={form.control}
                    name="code"
                    rules={{ required: true, }}

                    render={({ field }) => (
                        <FormItem className="space-x-1">
                            <FormLabel>Verification Code</FormLabel>
                            <FormControl>
                                <Input
                                    {...field}

                                    placeholder="Enter Code"
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />





                <Button type="submit" className="w-full space-x-1" loading={isLoaderVisible}>



                    Submit

                </Button>
            </form>
        </Form>
    )
}



export default AccountVerificationForm