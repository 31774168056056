
import { Select, SelectItem, SelectTrigger, SelectContent } from '@/components/ui/select';





const rowsLimitOpts = [10, 20, 50, 100, 200, 500, 1000];

const limitOpts = rowsLimitOpts.map((count) => {
    return {
        label: `${count} rows`,
        value: count.toString()
    }
})
export interface TableRowsLimitSelectorProps {
    rowsLimit: string,
    onRowsLimitChange: (limit: string) => void
}
const TableRowsLimitSelector = ({
    rowsLimit,
    onRowsLimitChange
}: TableRowsLimitSelectorProps) => {



    const handleChange = (val: string) => {
        onRowsLimitChange?.(val)
    }
    return (
        <div className="w-48">
            <Select value={rowsLimit} onValueChange={handleChange}>
                <SelectTrigger>
                    Show {rowsLimit} rows per page
                </SelectTrigger>
                <SelectContent>

                    {
                        limitOpts.map((opt, i) => <SelectItem key={`row-limit-count-${i}`} value={opt.value}>{opt.label}</SelectItem>)
                    }

                </SelectContent>
            </Select>
        </div>
    )
}


export default TableRowsLimitSelector