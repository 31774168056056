import { UpdatableRideData } from "@/typings";
import { create } from "zustand";
import isEmpty from "lodash/isEmpty";
/**
 * Type defs for Sidebar Store
 */
interface EditRideDetailsStore {
  /**
   * Ride data which will be edited
   */
  rideDetails: UpdatableRideData;
  /**
   * State of edit dialog
   *
   */
  isEditDialogOpen: boolean;
  /**
   * Open edit dialog
   * @param rideData Ride data which will be stored for editing
   */
  openEditDialog: (rideData?: UpdatableRideData) => void;

  /**
   * Close edit dialog
   */
  closeEditDialog: () => void;
}

/**
 * Custom hook created with zustand which stores data for the ride which is being edited
 */
export const useEditRideDetailsStore = create<EditRideDetailsStore>((set) => ({
  rideDetails: {} as UpdatableRideData,
  isEditDialogOpen: false,
  openEditDialog: (rideData) => {
    const validatedRideData = !isEmpty(rideData) ? rideData : {};
    set((state) => {
      return {
        ...state,
        isEditDialogOpen: true,
        rideDetails: validatedRideData as UpdatableRideData,
      };
    });
  },
  closeEditDialog: () => {
    set((state) => {
      return {
        ...state,
        isEditDialogOpen: false,
        rideDetails: {} as UpdatableRideData,
      };
    });
  },
}));
