/* eslint-disable @typescript-eslint/no-misused-promises */
import { GenericReqResponse, RideItemList, RideStatus } from "@/typings"
import { Button } from "./ui/button"
import { callCustomerForConfirmation, changeStatusOfManyRides } from "@/lib/helpers/rides"
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast"
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages"
import { extractErrorResponseFromHTTPRequest } from "@/lib/http"
import { AxiosError } from "axios"
import { useGlobalLoaderStore } from "@/lib/hooks/use-global-loader"
import useRideActions from "@/lib/hooks/use-ride-actions"



/**
 * Type defs for RidesActionsButtonRow props
 */
interface RidesActionsButtonRowProps {
    /**
     * List of rides
     */
    rides: RideItemList,
    /**
     * Callback which is called after status of rides is changed
     */
    onRideStatusChangeSuccess: (rides: RideItemList, rideStatus: RideStatus) => void | Promise<void>
    /**
     * Optional callback which will be called when call is successful
     */
    onCallSuccess?: (rides: RideItemList) => void,
    /**
     * Callback which will be called when clear selection btn is pressed
     */
    onClearSelection?: () => void

    onDeleteSuccess: (rides: RideItemList) => void

}


/**
 * Component which allows to perform some actions related to rides on the provided ride list
 */
const RidesActionsButtonRow = ({
    rides,
    onRideStatusChangeSuccess,
    onCallSuccess,
    onClearSelection,
    onDeleteSuccess,
}: RidesActionsButtonRowProps) => {

    const setLoaderVisible = useGlobalLoaderStore((state) => state.setLoaderVisible);

    const { removeRides } = useRideActions({
        onDeleteSuccess
    })

    /**
     * Initiats confirm call for the provides rides list
     */
    const handleCallConfirmation = async () => {

        try {
            setLoaderVisible(true);
            await callCustomerForConfirmation(rides.map((r) => r._id!));
            onCallSuccess?.(rides);
            showSuccessToast("Calls initiated ")
        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResponseData = extractErrorResponseFromHTTPRequest(error as AxiosError<GenericReqResponse>);

            if (errorResponseData.message) {
                errorMsg = errorResponseData.message
            }

            showErrorToast(errorMsg);
        } finally {
            setLoaderVisible(false)
        }

    }

    /**
     * Changes status of rides 
     * @param rideStatus Ride status for update
     * @returns 
     */
    const handleRideStatusChange = (rideStatus: RideStatus) => async () => {
        try {
            setLoaderVisible(true);
            await changeStatusOfManyRides(rides.map((r) => r._id!), rideStatus);
            onRideStatusChangeSuccess?.(rides, rideStatus);
            showSuccessToast("Status Changed")
        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResponseData = extractErrorResponseFromHTTPRequest(error as AxiosError<GenericReqResponse>);

            if (errorResponseData.message) {
                errorMsg = errorResponseData.message
            }

            showErrorToast(errorMsg);
        } finally {
            setLoaderVisible(false);
        }
    }


    /**
     * Handle click on delete button
     */
    const handleDeleteRides = () => {
        removeRides(rides)
    }

    return (

        <div className="flex gap-x-2">
            <Button onClick={handleCallConfirmation}>
                Call for Confirmation
            </Button>
            <Button onClick={handleRideStatusChange("confirmed")} >
                Mark as Confirmed
            </Button>
            <Button variant="destructive" onClick={handleRideStatusChange("cancelled")}>
                Mark as Cancelled
            </Button>

            <Button variant="destructive" onClick={handleDeleteRides}>
                Remove Rides
            </Button>

            <Button variant="ghost" onClick={onClearSelection}>
                Clear Selection
            </Button>
        </div>
    )
}


export default RidesActionsButtonRow