// import { AUTH_SESSION_COOKIE_NAME } from "@/variables/cookies";
import axios, {
  AxiosError,
  // AxiosHeaderValue
} from "axios";
// import Cookies from "js-cookie";

/**
 * Created a custom instance of axios which is used for access protected endpoints
 *
 * It passed auth token in request data in every call
 */
const http = axios.create({
  baseURL: import.meta.env.VITE_APP_BACKEND_BASE_URL + "/api/v1",
  headers: {
    // Authorization: {
    //   toString: function () {
    //     const authToken = Cookies.get(AUTH_SESSION_COOKIE_NAME);
    //     return `Bearer ${authToken}`;
    //   },
    // } as AxiosHeaderValue,
  },
  withCredentials: true,
});

/**
 * Extracts resonse body from the provided http error object
 * @param requestData Response data
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorResponseFromHTTPRequest = <T = Record<string, any>>(
  requestData: AxiosError<T> | Error
) => {
  let errorResponse = undefined;
  if ((requestData as AxiosError<T>).response) {
    errorResponse = (requestData as AxiosError<T>).response?.data;
  }

  return errorResponse as T;
};

/**
 * Exposed raw axios instance
 */
export const rawHttp = axios;

/**
 * Exposed custom axios instance
 */
export default http;
