import { Outlet } from "react-router-dom"
import SettingsSidebar from "./settings-sidebar"
import { settingsNavItems } from "@/data/nav"


/**
 * Component which shows settings view and its children routes
 */
const SettingsDashboard = () => {


    return (
        <div className='flex flex-1 flex-col space-y-8 md:space-y-2 md:overflow-hidden lg:flex-row lg:space-x-12 lg:space-y-0'>
            <aside className='top-0 lg:sticky lg:w-1/5'>
                <SettingsSidebar items={settingsNavItems} />
            </aside>
            <div className='flex w-full p-1 pr-4 md:overflow-y-hidden'>
                <Outlet />
            </div>
        </div>
    )
}


export default SettingsDashboard