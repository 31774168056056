import { XIcon } from "lucide-react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { cn } from "@/lib/utils"
import { MouseEvent } from "react";

/**
 * Type defs for SearchInput Props
 */
interface SearchInputProps {
    /**
     * Text value
     */
    value: string,
    /**
     * Callback which is triggered when value is changed
     */
    onChange: (val: string) => void,

    /**
     * You can pass custom classes
     */
    className?: string,
}

const SearchInput = ({
    value,
    onChange,
    className,
}: SearchInputProps) => {


    /**
     * Handle click on clear button
     * @param e Click event data
     */
    const handleClearBtnClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        onChange("")
    }
    return (

        <div className={cn(" flex min-h-9 items-center rounded-md border border-input px-3 shadow-sm focus-within:ring-1 focus-within:ring-ring  ", className)}>



            <Input
                placeholder="Search rides by Trip Id, Customer Name, Address, Phone"
                value={value}
                onChange={(event) => onChange(event.target.value)}
                className="mr-2 h-auto min-w-96 rounded-none border-none p-0 shadow-none focus-visible:ring-0"
            />

            {
                !!value &&

                <Button variant="ghost" size="icon" className="size-auto" onClick={handleClearBtnClick}>
                    <XIcon className="text-foreground"></XIcon>
                </Button>
            }



        </div>
    )
}



export default SearchInput;