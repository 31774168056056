

import { Badge } from '@/components/ui/badge';
import Typography from '@/components/typography';
import { cn } from '@/lib/utils';
import { isEmpty } from 'lodash';

/**
 * Type defs for AcceptedFileFormats props
 */
export interface AcceptedFileFormatsProps {
    acceptedFileFormats: string[],
    className?: string;
}

/**
 * Component which allows to display list of accepted file formats
 */
export const AcceptedFileFormats = ({
    acceptedFileFormats,
    className,
}: AcceptedFileFormatsProps) => {



    /**
     * Return nothing if list of accepted file formats is empty
     */
    if (isEmpty(acceptedFileFormats)) {
        return null;
    }
    return (

        <div
            className={cn('mt-3 ml-[1.125rem] flex items-center gap-x-3', className)}
        >
            {acceptedFileFormats.map((format) => {
                return (
                    <Badge
                        key={`file-format-${format}`}
                        className=" px-2.5 py-px "
                    >
                        <Typography
                            variant="s1"
                            className="leading-[1.4]"
                        >
                            {format}
                        </Typography>
                    </Badge>
                );
            })}
        </div>
        // </div>
    );
};
