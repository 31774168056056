import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { ReactNode } from "react";
import { toast, ExternalToast } from "sonner";

/**
 *
 * @param toastMsg Toast message or react node
 * @param data Options of toast
 */
export const showSuccessToast = (
  toastMsg: string | ReactNode,
  data?: ExternalToast
) => {
  toast.success(toastMsg, {
    ...data,
    classNames: {
      toast: "!bg-green-500",
      success: "!text-white",
    },
  });
};

/**
 *
 * @param toastMsg Toast message or react node
 * @param data Options of toast
 */
export const showErrorToast = (
  toastMsg: string | ReactNode,
  data?: ExternalToast
) => {
  toast.error(toastMsg ?? DEFAULT_ERROR_MESSAGE, {
    ...data,
    classNames: {
      toast: "!bg-red-500",
      error: "!text-white",
    },
  });
};
