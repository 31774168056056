// import ProfileForm from './profile-form'
import ContentSection from "@/components/content-section";
import UserProfileForm from "./user-profile-form";

/**
 * Component which displays user profile form in the settings view
 */
const UserProfileSettingsView = () => {
  return (
    <ContentSection
      title='Profile'
      desc='You can update your profile details here'
    >

      <UserProfileForm />
      {/* <ProfileForm /> */}
    </ContentSection>
  )
}



export default UserProfileSettingsView;