import * as React from 'react'
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { Button } from './button'
import { cn } from '@/lib/utils'

export type PasswordInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>


/**
 * Component shows an input which allows entering sceret value
 */
const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
    ({ className, ...props }, ref) => {
        const [showPassword, setShowPassword] = React.useState(false)
        return (
            <div className='relative rounded-md'>
                <input
                    type={showPassword ? 'text' : 'password'}
                    className={cn(
                        'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
                        className
                    )}
                    ref={ref}
                    {...props}
                />
                <Button
                    type='button'
                    size='icon'
                    variant='ghost'
                    className='absolute right-1 top-1/2 size-6 -translate-y-1/2 rounded-md text-muted-foreground'
                    onClick={() => setShowPassword((prev) => !prev)}
                >
                    {showPassword ? <EyeIcon size={18} /> : <EyeOffIcon size={18} />}
                </Button>
            </div>
        )
    }
)
PasswordInput.displayName = 'PasswordInput'

export { PasswordInput }
