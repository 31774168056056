/**
 * Date and time format used to display date and time
 */
export const DATE_TIME_FORMAT_IN_USE = "M/dd/yyyy hh:mm a";

/**
 * Date Format which is used to display date in readable format
 */
export const DATE_DISPLAY_FORMAT_IN_USE = "M/dd/yyyy";

/**
 * Date Format used internally
 */
export const DATE_INTERNAL_FORMAT_IN_USE = "yyyy-MM-dd";

/**
 * ISO format used internally
 */
export const ISO_DATE_TIME_FORMAT_IN_USE = "yyyy-MM-dd'T'HH:mm:ssZZ";
