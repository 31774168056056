
// import Loader from './Loader';
import styles from './Loader.module.css';
import PulseLoader from "react-spinners/PulseLoader";

/**
 * Component which displays loading animation with an overlay
 */
const LoaderWithOverlay = () => {
  return (
    <div className={styles['loader-overlay']}>
      <div className="flex h-full w-full items-center  justify-center bg-white/70">
        <PulseLoader color='hsl(var(--primary))' />
      </div>
    </div>
  );
};

export default LoaderWithOverlay;
