import {
  FrontendApi,
  Configuration,
  RegistrationFlow,
  LoginFlow,
  VerificationFlow,
  RecoveryFlow,
  UiNodeInputAttributes,
} from "@ory/kratos-client";
import { isEmpty } from "lodash";

/**
 * Client for ory indentity system
 */
export const frontendClient = new FrontendApi(
  new Configuration({
    basePath: import.meta.env.VITE_APP_BACKEND_BASE_URL, // Use your local Ory Tunnel URL
    baseOptions: {
      withCredentials: true, // we need to include cookies
    },
  })
);

/**
 * This function extracts error message from ory error response data
 * @param response Error response data
 */
export const extractErrorMessageFromOryResponse = (
  response: RegistrationFlow | LoginFlow | VerificationFlow | RecoveryFlow
) => {
  let errorMessageData = "";
  const uiNodes = response.ui.nodes;

  if (
    !isEmpty(response.ui.messages) &&
    response.ui.messages?.[0].type === "error"
  ) {
    errorMessageData = response.ui.messages?.[0].text;
  }

  if (!errorMessageData) {
    for (const uiNode of uiNodes) {
      if (
        uiNode.type === "input" &&
        !isEmpty(uiNode.messages) &&
        uiNode.messages?.[0].type === "error"
      ) {
        if (isEmpty(errorMessageData)) {
          errorMessageData = uiNode.messages[0].text;
          break;
        }
      }
    }
  }

  return errorMessageData;
};

/**
 * This function extracts csrf token from ory response
 * @param response Ory Response data
 */
export const extractCsrfTokenFromOryResponse = (
  response: RegistrationFlow | LoginFlow | VerificationFlow | RecoveryFlow
) => {
  const csrfTokenData = response.ui.nodes.find((n) => {
    return (
      n.type === "input" &&
      (n.attributes as UiNodeInputAttributes).name === "csrf_token"
    );
  });
  const csrfToken = (csrfTokenData?.attributes as UiNodeInputAttributes)
    .value as string;

  return csrfToken;
};

/**
 * This function extracts success response message from the response data
 * @param response Success Response data
 * @returns
 */
export const extractSuccessMessageFromOryResponse = (
  response: RegistrationFlow | LoginFlow | VerificationFlow | RecoveryFlow
) => {
  let successMessage = "";

  if (
    !isEmpty(response.ui.messages) &&
    response.ui.messages?.[0].type === "info"
  ) {
    successMessage = response.ui.messages?.[0].text;
  }

  return successMessage;
};
