import { useState } from "react";

/**
 * Custom hook which stores loader related state
 */
const useLoader = (defaultState: boolean) => {
  const [isLoaderVisible, setLoaderVisible] = useState(defaultState);

  return {
    isLoaderVisible,
    setLoaderVisible,
  };
};

export default useLoader;
