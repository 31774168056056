/* eslint-disable @typescript-eslint/no-misused-promises */

import { Button } from "@/components/custom/button";
import { Input } from "@/components/ui/input";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { ContinueWithRecoveryUi, RecoveryFlow, VerificationFlow } from "@ory/kratos-client";
import { z } from "zod"
import { AxiosError } from "axios";
import { extractCsrfTokenFromOryResponse, extractErrorMessageFromOryResponse, extractSuccessMessageFromOryResponse } from "@/lib/ory";
import { createUserLogoutFlow, getUserSettingsFlowData, logoutUser, resetUserPassword, sendRecoveryCodeEmail, submitAccountRecoveryCode } from "@/lib/helpers/user";
import { RawAccountRecoveryData, RawPasswordResetData } from "@/typings";
import { useRef } from "react";
import useLoader from "@/lib/hooks/use-loader";
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { useNavigate } from "react-router-dom";
import { PasswordInput } from "./custom/password-input";

/**
 * Type defs for AccountRecoveryForm 
 */
export interface AccountVerificationFormProps {
    /**
     * Recovery flow data 
     */
    flowData: VerificationFlow
}

/**
 * Recovery form schema 
 */
const formSchema = z.object({
    email: z.string({ "required_error": "Email is required" }).trim().min(1, "Email is required").email("Email is not valid"),
    code: z.string().optional(),
    isEmailSubmitted: z.boolean().optional(),
    isCodeValid: z.boolean().optional(),
    newPassword: z.string().optional(),
    confirmNewPassword: z.string().optional(),
}).superRefine((data, ctx) => {
    if (data.isEmailSubmitted && !data.code) {
        ctx.addIssue({
            path: ['code'],
            message: "Code is required",
            code: z.ZodIssueCode.custom,
        });
    }

    if (data.isCodeValid) {
        const newPassword = data.newPassword?.trim();
        const confirmNewPassword = data.confirmNewPassword?.trim();
        if (!newPassword) {
            ctx.addIssue({
                path: ['newPassword'],
                message: "Password is required",
                code: z.ZodIssueCode.custom,
            });
        }

        if (!confirmNewPassword) {
            ctx.addIssue({
                path: ['confirmNewPassword'],
                message: "This field is required",
                code: z.ZodIssueCode.custom,
            });
        }

        if (newPassword !== confirmNewPassword) {
            ctx.addIssue({
                path: ['confirmNewPassword'],
                message: "Passwords do not match",
                code: z.ZodIssueCode.custom,
            });
        }


    }


})

/**
 * Form component for Account Recovery view 
 */
const AccountRecoveryForm = ({ flowData }: AccountVerificationFormProps) => {

    // Ref variable to store updated csrf token
    const updatedCsrfTokenRef = useRef<string>("")
    // Ref variable to store settings flow id
    const settingsFlowIdRef = useRef<string>("");

    const { isLoaderVisible, setLoaderVisible } = useLoader(false)
    const navigate = useNavigate();

    // Form created via react-hook-form
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
            code: "",
            isEmailSubmitted: false,
            isCodeValid: false,
            newPassword: "",
            confirmNewPassword: ""
        },

    })



    // Watched form value 
    const isEmailSubmitted = form.watch("isEmailSubmitted")
    // Watched form value
    const isRecoveryCodeValid = form.watch("isCodeValid");


    /**
     * This function sends recovery code to the provided email
     * @param recoveryEmail Email of the user account
     */
    const sendCodeToEmail = async (recoveryEmail: string) => {
        try {
            setLoaderVisible(true);
            const csrfToken = extractCsrfTokenFromOryResponse(flowData);
            const res = await sendRecoveryCodeEmail({ csrfToken, email: recoveryEmail }, flowData.id);
            const updatedCsrfToken = extractCsrfTokenFromOryResponse(res);

            const successMsg = extractSuccessMessageFromOryResponse(res);
            updatedCsrfTokenRef.current = updatedCsrfToken;
            form.setValue("isEmailSubmitted", true);

            showSuccessToast(successMsg || "Please check your email for the recovery code")

        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE
            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as RecoveryFlow;
                errorMessage = extractErrorMessageFromOryResponse(errorResponse);
            }
            showErrorToast(errorMessage)
        } finally {
            setLoaderVisible(false)
        }
    }

    /**
     * This function sends recovery code to the backend 
     * @param data Recovery code data
     */
    const sendRecoveryCode = async (data: RawAccountRecoveryData) => {

        try {


            setLoaderVisible(true);
            const accountRecoveryData = { ...data };
            const res = await submitAccountRecoveryCode(accountRecoveryData, flowData.id);

            const errorMessage = extractErrorMessageFromOryResponse(res)

            if (errorMessage) {
                showErrorToast(errorMessage);
                return
            }

            if (res.state === "passed_challenge") {
                const continueWithData = res.continue_with?.[0] as ContinueWithRecoveryUi;
                const settingsFlowData = await getUserSettingsFlowData(continueWithData.flow.id);
                updatedCsrfTokenRef.current = extractCsrfTokenFromOryResponse(settingsFlowData);
                settingsFlowIdRef.current = continueWithData.flow.id;
                form.setValue("isCodeValid", true);

                showSuccessToast("Please enter a new Password")

            }
        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE;

            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as RecoveryFlow;
                errorMessage = extractErrorMessageFromOryResponse(errorResponse);
            }
            showErrorToast(errorMessage)
        } finally {
            setLoaderVisible(false)
        }

    }

    /**
     * This function is called when form is submitted 
     * @param values Form values
     */
    async function onSubmit(values: z.infer<typeof formSchema>) {

        try {


            const csrfToken = updatedCsrfTokenRef.current || extractCsrfTokenFromOryResponse(flowData);
            const formValues = values;

            if (!isEmailSubmitted) {
                sendCodeToEmail(formValues.email);
                return;
            }

            if (!isRecoveryCodeValid) {
                sendRecoveryCode({ csrfToken, code: formValues.code })
                return;
            }

            setLoaderVisible(true)
            const newPasswordData: RawPasswordResetData = {
                password: values.newPassword!,
                csrfToken: updatedCsrfTokenRef.current,
            }

            await resetUserPassword(newPasswordData, settingsFlowIdRef.current);

            const logoutFlowData = await createUserLogoutFlow();
            await logoutUser(logoutFlowData.logout_token);
            showSuccessToast("Password reset successfully!")
            setLoaderVisible(false)

            navigate("/login");
        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE;
            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as RecoveryFlow;
                errorMessage = extractErrorMessageFromOryResponse(errorResponse);
            }
            showErrorToast(errorMessage)
            setLoaderVisible(false)
        }

    }





    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">


                {!isEmailSubmitted &&
                    <>
                        <div className='mb-2 flex flex-col space-y-2 text-left'>
                            <h1 className='text-md font-semibold tracking-tight'>
                                Forgot Password
                            </h1>
                            <p className='text-sm text-muted-foreground'>
                                Enter your registered email and <br /> we will send you a link
                                to reset your password.
                            </p>
                        </div>

                        <FormField

                            control={form.control}
                            name="email"
                            rules={{ required: true }}

                            render={({ field }) => (
                                <FormItem className="space-x-1">
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Enter Your Email"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />


                    </>


                }


                {

                    isEmailSubmitted && !isRecoveryCodeValid &&

                    <>
                        <div className='mb-2 flex flex-col space-y-2 text-left'>

                            <p className='text-sm text-muted-foreground'>
                                Enter the recovery code which we sent on your registered email
                            </p>
                        </div>

                        <FormField

                            control={form.control}
                            name="code"
                            rules={{ required: true, }}

                            render={({ field }) => (
                                <FormItem className="space-x-1">
                                    <FormLabel>Recovery Code</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}

                                            placeholder="Enter Code"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </>


                }

                {
                    isRecoveryCodeValid ?


                        <>


                            <div className='mb-2 flex flex-col space-y-2 text-left'>

                                <p className='text-sm text-muted-foreground'>
                                    Enter the new password for your account
                                </p>
                            </div>

                            <FormField

                                control={form.control}
                                name="newPassword"

                                rules={{ required: true, minLength: 8, }}

                                render={({ field }) => (
                                    <FormItem className="space-x-1">
                                        <FormLabel>New Password</FormLabel>
                                        <FormControl>
                                            <PasswordInput
                                                {...field}

                                                placeholder="Enter New Password"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField

                                control={form.control}
                                name="confirmNewPassword"
                                rules={{ required: true, minLength: 8, }}
                                render={({ field }) => (
                                    <FormItem className="space-x-1">
                                        <FormLabel>Confirm New Password</FormLabel>
                                        <FormControl>
                                            <PasswordInput
                                                {...field}

                                                placeholder="Re-Enter Your New Password"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </>

                        : null
                }






                <Button className='!mt-4 w-full' loading={isLoaderVisible}>
                    Continue
                </Button>
            </form>
        </Form>
    )
}


export default AccountRecoveryForm