import { isEmpty } from "lodash";
import { getCurrentUserSession } from "./user";
import { redirect } from "react-router-dom";

/**
 * This is a route loader function which checks if the current user session is valid
 * then redirects the user to dashboard page else returns true to continue the flow
 */
export const checkUserSessionAndRedirectToDashboard = async () => {
  let isUserLoggedIn = false;
  try {
    const currUserSession = await getCurrentUserSession();

    isUserLoggedIn = !isEmpty(currUserSession);
  } catch {
    // Ingore error
  }

  if (isUserLoggedIn) {
    return redirect("/");
  }

  return true;
};

/**
 * This is a route loader function which checks if user session is valid
 * then returns user data else redirects to login page
 */
export const checkUserSessionAndRedirectToLogin = async () => {
  const responseBody = {
    isUserLoggedIn: false,
    userSessionData: {},
  };
  try {
    const currUserSession = await getCurrentUserSession();
    responseBody.isUserLoggedIn = true;
    responseBody.userSessionData = currUserSession;
  } catch {
    // Ingore error
  }

  if (!responseBody.isUserLoggedIn) {
    return redirect("/login");
  }

  return responseBody;
};
