import * as React from "react"

import { cn } from "@/lib/utils"
import { Label, LabelProps } from "./label"

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>


export type InputWithLabelProps = InputProps & { labelProps?: LabelProps, label?: string };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"


const InputWithLabel = React.forwardRef<HTMLInputElement, InputWithLabelProps>((props, ref) => {
  const { labelProps, className, label, ...inputProps } = props;
  return (
    <div className={cn("grid w-full max-w-sm items-center gap-1.5", className)}>
      <Label {...labelProps} >{label}</Label>
      <Input {...inputProps} ref={ref} />
    </div>
  )
})


InputWithLabel.displayName = "InputWithLabel"



export { Input, InputWithLabel }
