import * as React from 'react'
import { PlusCircledIcon } from '@radix-ui/react-icons'

import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from '@/components/ui/command'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { Check } from 'lucide-react'

interface DataTableFacetedFilterProps {
    /**
     * Title for the filter btn
     */
    title?: string
    /**
     * List of options to show in popup menu
     */
    options: {
        label: string
        value: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
    /**
     * Selected value
     */
    selection: string,
    /**
     * Callback which will be called when an option is selected
     * 
     * @param selection Selected value
     */
    setSelection: (selection: string) => void
}


/**
 * Component which allows selecting filter option 
 */
export function DataTableFacetedFilter({
    title,
    options,
    selection,
    setSelection
}: DataTableFacetedFilterProps) {

    /**
     * State to open or close popup 
     */
    const [open, setOpen] = React.useState(false)
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant='outline' className='border-dashed hover:bg-secondary/30'>
                    <PlusCircledIcon className='mr-2 size-4' />
                    {title}
                    {!!selection && (
                        <>
                            <Separator orientation='vertical' className='mx-2 h-4' />
                            <div className='hidden space-x-1 lg:flex'>
                                {
                                    options
                                        .filter((option) => option.value === selection)
                                        .map((option) => (
                                            <Badge
                                                variant='secondary'
                                                key={option.value}
                                                className='rounded-sm px-1 font-medium'
                                            >
                                                {option.label}
                                            </Badge>
                                        ))
                                }
                            </div>
                        </>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-[200px] p-0' align='start'>
                <Command>
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                            {options.map((option) => {
                                const isSelected = selection === option.value
                                return (
                                    <CommandItem
                                        key={option.value}
                                        onSelect={() => {
                                            setSelection(option.value)
                                            setOpen(false);
                                        }}
                                    >
                                        <Check
                                            className={cn(
                                                "mr-2 h-4 w-4",
                                                isSelected ? "opacity-100" : "opacity-0"
                                            )}
                                        />
                                        {option.icon && (
                                            <option.icon className='mr-2 size-4 text-muted-foreground' />
                                        )}
                                        <span>{option.label}</span>
                                    </CommandItem>
                                )
                            })}
                        </CommandGroup>
                        {!!selection && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => {
                                            setSelection("")
                                            setOpen(false);
                                        }}
                                        className='justify-center text-center'
                                    >
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}