import { useNavigate, useRouteError } from 'react-router-dom'
import { Button } from '@/components/custom/button'
import { cn } from '@/lib/utils'
import { useEffect } from 'react';
import * as Sentry from "@sentry/react";
interface GeneralErrorProps extends React.HTMLAttributes<HTMLDivElement> {
    pageNotFound?: boolean,

}

/**
 * Component to gracefully handle error 
 */
export default function GeneralError({
    className,
    pageNotFound
}: GeneralErrorProps) {
    const navigate = useNavigate()
    const error = useRouteError() as Error;

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);
    return (
        <div className={cn('h-svh w-full', className)}>
            <div className='m-auto flex size-full flex-col items-center justify-center gap-2'>
                {
                    pageNotFound ?
                        <>
                            <h1 className='text-[7rem] font-bold leading-tight'>404</h1>
                            <p className='text-center text-muted-foreground'>
                                It seems like the page you&apos;re looking for <br />
                                does not exist or might have been removed.
                            </p>
                        </>
                        :

                        <>

                            <h1 className='text-[7rem] font-bold leading-tight'>Oops!</h1>
                            <span className='font-medium'>Something went wrong {`:')`}</span>

                            <p className='text-center text-muted-foreground'>
                                We apologize for the inconvenience. <br /> Please try again later.
                            </p>
                        </>
                }




                <div className='mt-6 flex gap-4'>
                    <Button variant='outline' onClick={() => navigate(-1)}>
                        Go Back
                    </Button>
                    <Button onClick={() => navigate('/')}>Back to Home</Button>
                </div>

            </div>
        </div>
    )
}
