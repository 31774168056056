import type {
  RawAccountRecoveryData,
  RawAccountVerificationData,
  RawPasswordResetData,
  RawUpdateUserSettingsData,
  RawUserData,
  RawUserLoginData,
  UserData,
} from "@/typings";

import { frontendClient } from "../ory";
import {
  Session,
  UpdateSettingsFlowWithPasswordMethod,
  UpdateSettingsFlowWithProfileMethod,
} from "@ory/kratos-client";
import { capitalizeAllFirstLetters } from "../capitalize";
import { isEmpty } from "lodash";

/**
 * This function creates user registration flow
 */
export const createUserRegistrationFlow = async () => {
  return (await frontendClient.createBrowserRegistrationFlow()).data;
};

/**
 * This function creates user login flow
 */
export const createUserLoginFlow = async () => {
  return (await frontendClient.createBrowserLoginFlow()).data;
};

/**
 *
 * This function creates user account recovery flow
 */
export const createUserAccountRecoveryFlow = async () => {
  return (await frontendClient.createBrowserRecoveryFlow()).data;
};

/**
 * This function sends recovery code to the provided email
 * @param values Recovery flow data
 * @param flowId Recovery flow id
 */
export const sendRecoveryCodeEmail = async (
  values: RawAccountRecoveryData,
  flowId: string
) => {
  const res = await frontendClient.updateRecoveryFlow({
    flow: flowId,
    updateRecoveryFlowBody: {
      method: "code",
      csrf_token: values.csrfToken,
      email: values.email,
    },
  });

  return res.data;
};

/**
 * This function submits recovery code to the ory identity system
 * @param values Recovery flow data
 * @param flowId Recovery flow id
 */
export const submitAccountRecoveryCode = async (
  values: RawAccountRecoveryData,
  flowId: string
) => {
  const res = await frontendClient.updateRecoveryFlow({
    flow: flowId,
    updateRecoveryFlowBody: {
      method: "code",
      csrf_token: values.csrfToken,
      code: values.code,
    },
  });

  return res.data;
};

/**
 * This function submits the user data to the ory identity system
 * @param userData User data
 * @param flowId Flow id
 */
export const registerUser = async (userData: RawUserData, flowId: string) => {
  const res = await frontendClient.updateRegistrationFlow({
    flow: flowId,
    updateRegistrationFlowBody: {
      method: "password",
      password: userData.password,
      traits: {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
      },
      csrf_token: userData.csrfToken,
    },
  });

  return res.data;
};

/**
 * This function submits the user login data to the ory identity system
 * @param loginData Login Data
 * @param flowId Flow id
 */
export const loginUser = async (
  loginData: RawUserLoginData,
  flowId: string
) => {
  const res = await frontendClient.updateLoginFlow({
    flow: flowId,
    updateLoginFlowBody: {
      identifier: loginData.email,
      csrf_token: loginData.csrfToken,
      password: loginData.password,
      method: "password",
    },
  });

  return res.data;
};

/**
 * This function returns the settings flow by the provided flow id from ory identity system
 * @param flowId Flow id
 */
export const getUserSettingsFlowData = async (flowId: string) => {
  const res = await frontendClient.getSettingsFlow({
    id: flowId,
  });

  return res.data;
};

/**
 * This function submits password reset data to the ory identity system
 * @param data  Password data
 * @param flowId Flow id
 */
export const resetUserPassword = async (
  data: RawPasswordResetData,
  flowId: string
) => {
  const res = await frontendClient.updateSettingsFlow({
    flow: flowId,
    updateSettingsFlowBody: {
      method: "password",
      password: data.password,
      csrf_token: data.csrfToken,
    },
  });

  return res.data;
};

/**
 * This function returns the current active user session from ory identity system
 */
export const getCurrentUserSession = async () => {
  const res = await frontendClient.toSession();

  return res.data;
};

/**
 * This function allows to disable session provided by the session id
 * @param sessionId Session Id
 */
export const removeUserSession = async (sessionId: string) => {
  const res = await frontendClient.disableMySession({
    id: sessionId,
  });

  return res.data;
};

/**
 * This function creates user logout flow
 */
export const createUserLogoutFlow = async () => {
  const res = await frontendClient.createBrowserLogoutFlow();

  return res.data;
};

/**
 * This function logouts the user with the provided logout token
 * @param logoutToken Logout token
 */
export const logoutUser = async (logoutToken: string) => {
  const res = await frontendClient.updateLogoutFlow({
    token: logoutToken,
  });

  return res.data;
};

export const createAccountVerificationFlow = async () => {
  const res = await frontendClient.createBrowserVerificationFlow();
  return res.data;
};

export const getAccountVerificationFlow = async (flowId: string) => {
  const res = await frontendClient.getVerificationFlow({
    id: flowId,
  });

  return res.data;
};

export const submitAccountVerificationCode = async (
  data: RawAccountVerificationData,
  flowId: string
) => {
  const res = await frontendClient.updateVerificationFlow({
    flow: flowId,
    updateVerificationFlowBody: {
      method: "code",
      code: data.code,
      csrf_token: data.csrfToken,
    },
  });

  return res.data;
};
/**
 * Create user settings flow
 */
export const createUserSettingsFlow = async () => {
  const res = await frontendClient.createBrowserSettingsFlow();

  return res.data;
};

/**
 * Submits the flow data to the indentity service
 * @param data Flow data
 * @param flowId flow id
 */
export const submitUserSettingsFlowData = async (
  data: RawUpdateUserSettingsData,
  flowId: string
) => {
  const dataToSend = {
    method: data.method,
    csrf_token: data.csrfToken,
  } as
    | UpdateSettingsFlowWithPasswordMethod
    | UpdateSettingsFlowWithProfileMethod;

  if (data.method === "password" && data.password) {
    (dataToSend as UpdateSettingsFlowWithPasswordMethod).password =
      data.password;
  }

  if (data.method === "profile") {
    (dataToSend as UpdateSettingsFlowWithProfileMethod).traits = {
      ...data.profileData,
    };
  }
  const res = await frontendClient.updateSettingsFlow({
    flow: flowId,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updateSettingsFlowBody: {
      ...dataToSend,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
  });

  return res.data;
};

export const combineUserName = (userData: Partial<UserData>) => {
  return capitalizeAllFirstLetters(
    `${userData.firstName} ${userData.lastName}`
  );
};
export const extractUserDataFromSessionData = (
  sessionData: Session
): UserData => {
  const userIndentityData = sessionData.identity;

  return {
    ...(userIndentityData?.traits as UserData),
    fullName: combineUserName({
      firstName: (userIndentityData?.traits as UserData).firstName,
      lastName: (userIndentityData?.traits as UserData).lastName,
    }),
    id: userIndentityData!.id,
  };
};

/**
 * This function checks if current user session is valid or not
 */
export const checkUserSession = async () => {
  let isUserLoggedIn = false;
  const currUserSession = await getCurrentUserSession();

  isUserLoggedIn = !isEmpty(currUserSession);

  return isUserLoggedIn;
};
