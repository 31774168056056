import { createUserAccountRecoveryFlow } from "@/lib/helpers/user";
import { RecoveryFlow } from "@ory/kratos-client"
import { useEffect, useState } from "react"
import { Card } from "@/components/ui/card";
import { isEmpty } from "lodash";
import AccountRecoveryForm from "@/components/account-recovery-form";
import { showErrorToast } from "@/lib/helpers/toast";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
// import { Link } from "react-router-dom";
import Loader from "@/components/Loader/Loader";


/**
 * Component which shows account recovery view
 */
const AccountRecoveryView = () => {

    const [recoveryFlowData, setRecoveryFlowData] = useState<RecoveryFlow>({} as RecoveryFlow);


    /**
     * Init Recovery flow
     */
    const initRecoveryFlow = async () => {

        try {
            const res = await createUserAccountRecoveryFlow();

            setRecoveryFlowData(res);
        } catch {
            showErrorToast(DEFAULT_ERROR_MESSAGE)
        }

    }

    useEffect(() => {
        initRecoveryFlow()
    }, [])
    return (


        <div className='container grid h-svh flex-col items-center justify-center bg-primary-foreground lg:max-w-none lg:px-0'>
            <div className='mx-auto flex w-full flex-col justify-center space-y-2 sm:w-[480px] lg:p-8'>
                <div className='mb-4 flex items-center justify-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='mr-2 h-6 w-6'
                    >
                        <path d='M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3' />
                    </svg>
                    <h1 className='text-xl font-medium'>YCB Caller</h1>
                </div>
                <Card className='p-6'>

                    {
                        !isEmpty(recoveryFlowData) ?
                            <AccountRecoveryForm flowData={recoveryFlowData} />
                            :

                            <div className="flex items-center justify-center">
                                <Loader type="pulse" />
                            </div>

                    }



                    {/* <p className='mt-4 px-8 text-center text-sm text-muted-foreground'>
                        Don&apos;t have an account?{' '}
                        <Link
                            to='/sign-up'
                            className='underline underline-offset-4 hover:text-primary'
                        >
                            Sign up
                        </Link>
                        .
                    </p> */}
                </Card>
            </div>
        </div>

    )
}



export default AccountRecoveryView;