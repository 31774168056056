/* eslint-disable @typescript-eslint/no-misused-promises */

import { Button } from "@/components/custom/button";
import { Input } from "@/components/ui/input";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { registerUser } from "@/lib/helpers/user";
import { AxiosError } from "axios";
import { extractCsrfTokenFromOryResponse, extractErrorMessageFromOryResponse } from "@/lib/ory";
import {
    ContinueWithVerificationUi,
    RegistrationFlow,
} from "@ory/kratos-client";
import { cn, removeProp } from "@/lib/utils";
import { RawUserData } from "@/typings";

import useLoader from "@/lib/hooks/use-loader";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { PasswordInput } from "./custom/password-input";
import { HTMLAttributes } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast } from "@/lib/helpers/toast";


/**
 * Sign Up Form Schema
 */
const formSchema = z.object({
    firstName: z.string({ "required_error": "First Name is required" }).trim().min(1, "First Name is required"),
    lastName: z.string({ "required_error": "Last Name is required" }).trim().min(1, "Last Name is required"),
    email: z.string({ "required_error": "Email is required" }).trim().email("Email is not valid"),
    password: z.string({ "required_error": "Password is required" }).trim().min(8, "Password must be at least 8 characters long"),
    confirmPassword: z.string({ "required_error": "This field is required" }).trim(),

}).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
})


/**
 * Type def for SignUp Form
 */
interface SignupFormProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Registeration Flow data
     */
    flowData: RegistrationFlow
}



const SignUpForm = ({ flowData, className, ...props }: SignupFormProps) => {


    const { isLoaderVisible, setLoaderVisible } = useLoader(false);
    const navigate = useNavigate();

    /**
     * Form created by react-hook-form
     */
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
            firstName: "",
            lastName: "",
            confirmPassword: "",
            password: ""
        },

    })


    /**
     * This function is triggered when form is submitted 
     * @param values Form Values
     */
    async function onSubmit(values: z.infer<typeof formSchema>) {

        try {
            setLoaderVisible(true);
            const csrfToken = extractCsrfTokenFromOryResponse(flowData);
            const userData = { ...values };

            const cleanedValues = removeProp(userData, ["confirmPassword"]) as RawUserData;
            const res = await registerUser({ ...cleanedValues, csrfToken }, flowData.id)
            const continueFlowId = (res.continue_with?.[0] as ContinueWithVerificationUi).flow.id;
            navigate(`/account-verification?flow=${continueFlowId}`);

        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE;
            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as RegistrationFlow;
                errorMessage = extractErrorMessageFromOryResponse(errorResponse);
            }
            showErrorToast(errorMessage)

        } finally {
            setLoaderVisible(false);
        }

    }

    return (
        <>
            <div className='mb-2 flex flex-col space-y-2 text-left'>
                <h1 className='text-lg font-semibold tracking-tight'>
                    Create an account
                </h1>
                <p className='text-sm text-muted-foreground'>
                    Enter your details to create an account. <br />
                    Already have an account?{' '}
                    <Link
                        to='/login'
                        className='underline underline-offset-4 hover:text-primary'
                    >
                        Sign In
                    </Link>
                </p>
            </div>

            <div className={cn('grid gap-6', className)} {...props}>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className='grid gap-2'>


                            <FormField

                                control={form.control}
                                name="firstName"
                                rules={{ required: true }}

                                render={({ field }) => (
                                    <FormItem className="space-x-1">
                                        <FormLabel>First Name</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                placeholder="Enter First Name"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField

                                control={form.control}
                                name="lastName"
                                rules={{ required: true }}

                                render={({ field }) => (
                                    <FormItem className="space-x-1">
                                        <FormLabel>Last Name</FormLabel>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                placeholder="Enter Last Name"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='email'
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Email</FormLabel>
                                        <FormControl>
                                            <Input placeholder='name@example.com' {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='password'
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Password</FormLabel>
                                        <FormControl>
                                            <PasswordInput placeholder='********' {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name='confirmPassword'
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Confirm Password</FormLabel>
                                        <FormControl>
                                            <PasswordInput placeholder='********' {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <Button className='mt-2' loading={isLoaderVisible}>
                                Create Account
                            </Button>



                        </div>
                    </form>
                </Form>
            </div>
        </>

    )
}



export default SignUpForm;