
import { useCallback, useEffect, useRef, useState } from "react";
import DropzoneDialog from "./dropzone/dropzone-dialog";
import RidesTable from "./rides-table";
import { Button } from "./ui/button";
import { isEmpty } from "lodash";
import { useDebounceCallback } from '@react-hook/debounce';
import { callCustomerForConfirmation, changeStatusOfRide, getRidesList, getRideStatusFromRideData, getStoredLastSyncOfRides, getUpdatedRidesList, storeLastSyncOfRides, uploadRidesFile } from "@/lib/helpers/rides";
import { GenericReqResponse, GetRidesListResponse, GetRidesQueryParams, RideAction, RideDataItem, RideItemList, RideStatus, UploadRidesResponse } from "@/typings";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { AxiosError } from "axios";
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast";
import { extractErrorResponseFromHTTPRequest } from "@/lib/http";
import {
    RIDE_STATUS_CONFIG,
    RIDE_STATUS_OPTIONS,
    // RIDE_TYPE_OPTIONS,
} from "@/variables/rides";
import { DateTime } from "luxon"
import { getCurrentTimeInUTC } from "@/lib/helpers/datetime";
import useInterval from "@/lib/hooks/use-interval";
import RidesActionsButtonRow from "./rides-actions-button-row";
import { PaginationState, Table } from "@tanstack/react-table";

import { DatePickerWithRange } from "@/components/date-range-picker"
import { DateRange } from "react-day-picker";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import SearchInput from "./search-input";
import LoaderWithOverlay from "./Loader/LoaderWithOverlay";
import { useGlobalLoaderStore } from "@/lib/hooks/use-global-loader";
import useRideActions from "@/lib/hooks/use-ride-actions";
import { RefreshCwIcon } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { cn } from "@/lib/utils";
import UpdateRideDetailsDialog from "./update-ride-details-dialog/update-ride-details-dialog";



interface GetRidesFuncParams {
    /**
     * Query params for list 
     */
    queryParams?: Partial<GetRidesQueryParams>,
    /**
     * Enable auto pull for updated rides
     */
    enableAutoPull?: boolean,
    /**
     * Store Last Sync timestamp after getting list 
     */
    storeLastSync?: boolean,

    /**
     * Marks if the current list is being refreshed
     */
    isRefreshingList?: boolean
}


interface UpdateRideData {
    /**
     * Id of ride which will be updated
     */
    rideId: string,
    /**
     * Updated ride data 
     */
    rideData: Partial<RideDataItem>
}

/**
 * Accepted file types object
 */
const acceptedFileTypes = {
    ["text/csv"]: [".csv"]
}



const RidesDashboard = () => {

    const [isUploadDialogOpen, setUploadDialogOpen] = useState<boolean>(false)
    const [isBulkUpdateRidesDialogOpen, setUpdateRidesDialog] = useState<boolean>(false);
    const [ridesList, setRidesList] = useState<RideItemList>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const ridesTableRef = useRef<Table<RideDataItem>>();
    const [statusSelection, setStatusSelection] = useState<string>("");
    // const [rideTypeSelection, setRideTypeSelection] = useState<RideType>("appointment");
    const [dateRange, setDateRange] = useState<DateRange>();
    const [isRefreshingList, setIsRefreshingList] = useState(false);


    /**
     * Removes rides from local state when rides are removed
     * @param deletedRides List of deleted rides
     */
    const handleDeleteRidesSuccess = (deletedRides: RideItemList) => {
        let localRidesData = [...ridesList];
        localRidesData = localRidesData.filter((r) => !deletedRides.some((dR) => dR._id === r._id))
        setRidesList(localRidesData)

        handleClearSelection()
    }


    const { removeRides } = useRideActions({
        onDeleteSuccess: handleDeleteRidesSuccess,
    })
    /**
     * Ref variable to store state of loading rides
     */
    const isLoadingDataRef = useRef(false);
    /**
     * Global loader hook
     */
    const { isVisible: isLoaderVisible, setLoaderVisible } = useGlobalLoaderStore()
    /**
     * Pagination state for the table
     */
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    })

    /**
     * State to store enabling auto pull function
     */
    const [startAutoPull, setStartAutoPull] = useState<boolean>(false);


    const [selectedRides, setSelectedRides] = useState<RideItemList>([]);

    /**
     * Auto pull updated rides every 30 seconds
     */
    useInterval(() => {
        getUpdatedRidesAndUpdateLocalState()
    }, startAutoPull ? 30000 : null)


    /**
     * Get list of rides by query params
     * 
     */
    const getRides = useCallback(async (params: GetRidesFuncParams = {}) => {
        const {
            storeLastSync,
            queryParams,
            enableAutoPull,
            isRefreshingList
        } = params

        if (isLoadingDataRef.current) {
            return;
        }
        try {

            const searchQueryParam = queryParams?.query?.trim();
            isLoadingDataRef.current = true;
            setLoaderVisible(true);
            let storeLastSyncInStorage = false;
            if (storeLastSync) {
                storeLastSyncInStorage = true;
            }

            const res = await getRidesList({
                ...queryParams,
                status: statusSelection as RideStatus,
                startDate: dateRange?.from,
                endDate: dateRange?.to,
                query: searchQueryParam ? searchQueryParam : searchQuery?.trim(),
                type: "appointment"
            });
            let listToUse = res.list;
            if (statusSelection === RIDE_STATUS_CONFIG.IN_PROGRESS) {
                listToUse = listToUse.filter((r) => getRideStatusFromRideData(r) === RIDE_STATUS_CONFIG.IN_PROGRESS)
            }
            if (statusSelection === RIDE_STATUS_CONFIG.NO_ANSWER) {
                listToUse = listToUse.filter((r) => getRideStatusFromRideData(r) === RIDE_STATUS_CONFIG.NO_ANSWER)
            }
            setPagination({ pageIndex: res.page - 1, pageSize: res.limit })
            setRidesList(listToUse);
            setTotalCount(res.count);

            if (storeLastSyncInStorage) {
                storeLastSyncOfRides(DateTime.now().toISO())
            }

            if (enableAutoPull) {
                setStartAutoPull(true);
            }
            isLoadingDataRef.current = false;

            setTimeout(() => {
                setLoaderVisible(false)

                if (isRefreshingList) {
                    setIsRefreshingList(false)
                }
            }, 0)


        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResonse = extractErrorResponseFromHTTPRequest<GetRidesListResponse>(error as AxiosError);
            if (errorResonse) {
                errorMsg = errorResonse.message!;
            }
            isLoadingDataRef.current = false;
            showErrorToast(errorMsg)
            setLoaderVisible(false)
            setIsRefreshingList(false)
        }
    }, [setLoaderVisible, statusSelection, dateRange, searchQuery])
    const handleSubmit = useCallback(async (files: File[]) => {
        try {
            if (isEmpty(files)) {
                return;
            }

            setLoaderVisible(true);
            await uploadRidesFile(files[0]);

            const res = await getRidesList();
            setRidesList(res.list);
            setTotalCount(res.count);
            setPagination({
                pageIndex: 0,
                pageSize: pagination.pageSize
            })
            setUploadDialogOpen(false)
            showSuccessToast("Rides uploaded")
            setLoaderVisible(false);
        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResonse = extractErrorResponseFromHTTPRequest<UploadRidesResponse>(error as AxiosError);
            if (errorResonse) {
                errorMsg = errorResonse.message!;
            }
            showErrorToast(errorMsg)
            setLoaderVisible(false);
        }

    }, [])





    /**
     * Update local rides list by the provided list of ride data 
     * 
     */
    const updateLocalRidesData = useCallback((list: UpdateRideData[]) => {
        const localRidesData = [...ridesList];
        list.forEach((data) => {
            const { rideData, rideId } = data;
            const rideIndex = localRidesData.findIndex((r) => r._id === rideId);
            if (rideIndex >= 0) {
                localRidesData[rideIndex] = {
                    ...localRidesData[rideIndex],
                    ...rideData
                }
            }
        })



        setRidesList(localRidesData)
    }, [ridesList])

    /**
     * Fetch updated ride items based on last sync time and update local state
     */
    const getUpdatedRidesAndUpdateLocalState = async () => {
        try {
            const list = await getUpdatedRidesList(getStoredLastSyncOfRides())
            updateLocalRidesData(list.map((r) => ({
                rideData: { ...r },
                rideId: r._id!
            })))
            storeLastSyncOfRides(DateTime.now().toISO())

        } catch (e) {
            console.error("ERROR IN UPDATE LIST", e)
        }
    }


    /**
     * Handle action on the provided ride id
     * @param rideId Id of ride
     * @param rideAction Action on ride
     */
    const onRideActionSelect = async (rideId: string, rideAction: RideAction) => {
        const enableLoader = rideAction !== "delete-ride"
        try {
            if (enableLoader) {
                setLoaderVisible(true);

            }
            let successToastMsg = "";
            switch (rideAction) {
                case "call":
                    await callCustomerForConfirmation(rideId);
                    updateLocalRidesData([
                        {
                            rideId,
                            rideData: {
                                status: RIDE_STATUS_CONFIG.IN_PROGRESS,
                                callStartedAt: getCurrentTimeInUTC().toISO()
                            }
                        }
                    ])
                    successToastMsg = "Call initiated";
                    break;
                case "cancel-ride":
                    {
                        const rideStatus = RIDE_STATUS_CONFIG.CANCELLED
                        await changeStatusOfRide(rideId, rideStatus);
                        updateLocalRidesData([
                            {
                                rideId,
                                rideData: {

                                    status: rideStatus

                                }
                            }
                        ])

                        successToastMsg = "Ride Status Changed"

                        break;
                    }

                case "confirm-ride":
                    {
                        const rideStatus = RIDE_STATUS_CONFIG.CONFIRMED
                        await changeStatusOfRide(rideId, rideStatus);

                        updateLocalRidesData([
                            {
                                rideId,
                                rideData: {

                                    status: rideStatus

                                }
                            }
                        ])
                        successToastMsg = "Ride Status Changed";
                        break;
                    }
                case "delete-ride":
                    await removeRides([{ _id: rideId } as RideDataItem], false)
                    break;

            }


            if (successToastMsg) {
                showSuccessToast(successToastMsg);
            }
        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE;
            const errorResponseData = extractErrorResponseFromHTTPRequest<GenericReqResponse>(error as AxiosError);

            if (errorResponseData) {
                errorMessage = errorResponseData.message;
            }

            showErrorToast(errorMessage)
        } finally {

            if (enableLoader) {
                setLoaderVisible(false);
            }

        }
    }


    /**
     * Fetch new rides based on provided page
     * @param pageIndex Page Number
     */
    const onPageChange = async (pageIndex: number) => {
        await getRides({
            queryParams: {
                page: pageIndex + 1,
                limit: pagination.pageSize,
                // query: searchQuery?.trim(),
                // status: statusSelection as RideStatus,
                // startDate: dateRange?.from,
                // endDate: dateRange?.to
            },
        })
    }

    /**
     * Fetch rides on rows limit change
     */
    const handleRowsLimitChange = async (limit: number) => {
        await getRides({
            queryParams: {
                page: 1,
                limit,
            },
        })
    }

    const handleOnMultiRidesStatusChange = (rides: RideItemList, rideStatus: RideStatus) => {
        updateLocalRidesData(rides.map((r) => {
            return {
                rideId: r._id!,
                rideData: {
                    status: rideStatus
                }
            }
        }))

        handleClearSelection()
    }


    const handleClearSelection = () => {
        ridesTableRef.current?.resetRowSelection(true)
    }


    /**
     * Debounced function which runs search for rides
     *
     */
    const debouncedSearchFunc = useDebounceCallback(
        (searchQuery: string) => {
            const paginationData = ridesTableRef.current?.getState().pagination
            getRides({
                queryParams: {
                    query: searchQuery,
                    page: 1,
                    limit: paginationData?.pageSize
                }
            })

        },
        500
    );


    const handleDateSearch = useCallback(() => {
        const paginationData = ridesTableRef.current?.getState().pagination
        const currPage = paginationData?.pageIndex !== undefined ? paginationData?.pageIndex + 1 : 1;
        const currLimit = paginationData?.pageSize ?? 10;
        getRides({
            queryParams: {
                query: searchQuery,
                limit: currLimit,
                page: currPage,
            }
        })

    }, [searchQuery, getRides]);


    /**
     * Handle upload dialog state change
     * @param isOpen 
     * @returns 
     */
    const handleUploadDialogStateChange = (isOpen: boolean) => {
        if (isLoaderVisible) {
            return
        }
        setUploadDialogOpen(isOpen)
    }
    /**
     * Handle bulk update rides dialog state change
     * @param isOpen 
     * @returns 
     */
    const handleBulkUpdateRidesDialogStateChange = (isOpen: boolean) => {
        if (isLoaderVisible) {
            return
        }
        setUpdateRidesDialog(isOpen)
    }


    /**
     * Call debounced search function on search change
     */
    const handleSearchQuery = (val: string) => {
        setSearchQuery(val)
        debouncedSearchFunc(val);

    }

    /**
     * Handles call success 
     * @param rides List of rides 
     */
    const handleCallSuccess = (rides: RideItemList) => {
        updateLocalRidesData(rides.map((r) => {
            return {
                rideId: r._id!,
                rideData: {
                    status: RIDE_STATUS_CONFIG.IN_PROGRESS,
                    callStartedAt: getCurrentTimeInUTC().toISO()
                }
            }
        }))
        handleClearSelection()
    }


    const refreshCurrList = () => {

        const paginationData = ridesTableRef.current?.getState().pagination
        const currPage = paginationData?.pageIndex !== undefined ? paginationData?.pageIndex + 1 : 1;
        const currLimit = paginationData?.pageSize ?? 10;

        getRides({
            queryParams: {
                query: searchQuery,
                limit: currLimit,
                page: currPage,
            },
            isRefreshingList: true,
        })
    }

    /**
     * Refreshes list 
     */
    const handleRefreshList = () => {

        const paginationData = ridesTableRef.current?.getState().pagination
        const currPage = paginationData?.pageIndex !== undefined ? paginationData?.pageIndex + 1 : 1;
        const currLimit = paginationData?.pageSize ?? 10;
        setIsRefreshingList(true);
        getRides({
            queryParams: {
                query: searchQuery,
                limit: currLimit,
                page: currPage,
            },
            isRefreshingList: true,
        })
    }


    /**
     * Handle ride type selection change
     * @param rideType Type of ride
     */
    // const handleRideTypeSelection = (rideType: string) => {
    //     setRideTypeSelection(rideType as RideType)
    // }

    /**
     * Handle bulk rides upload submit
     */
    const handleBulkUploadSubmit = useCallback(async (files: File[]) => {
        try {

            if (isEmpty(files)) {
                return;
            }
            setLoaderVisible(true);
            const paginationData = ridesTableRef.current?.getState().pagination
            const currPage = paginationData?.pageIndex !== undefined ? paginationData?.pageIndex + 1 : 1;
            const currLimit = paginationData?.pageSize ?? 10;
            await uploadRidesFile(files[0], "update");
            await getRides({
                queryParams: {
                    limit: currLimit,
                    page: currPage,
                },
            })
            setUpdateRidesDialog(false)
            showSuccessToast("Bulk rides updated")
        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResonse = extractErrorResponseFromHTTPRequest<UploadRidesResponse>(error as AxiosError);
            if (errorResonse) {
                errorMsg = errorResonse.message!;
            }
            showErrorToast(errorMsg)
            setLoaderVisible(false);
        }

    }, [pagination, setLoaderVisible, getRides])




    useEffect(() => {
        // Get rides on mount
        getRides({ storeLastSync: true, enableAutoPull: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    // Fetch rides on status change
    useEffect(() => {
        getRides({
            queryParams: {
                startDate: dateRange?.from,
                endDate: dateRange?.to,
                query: searchQuery,
                limit: pagination.pageSize,
                page: 1,
            },
        })
    }, [statusSelection])


    // Fetch rides on ride type change
    // useEffect(() => {
    //     getRides({
    //         queryParams: {
    //             startDate: dateRange?.from,
    //             endDate: dateRange?.to,
    //             query: searchQuery,
    //             limit: pagination.pageSize,
    //             page: 1,
    //         },
    //     })
    // }, [rideTypeSelection])


    return (

        <>

            <div className="size-full overflow-hidden">
                <div className="flex size-full flex-col">
                    <div className="flex justify-between">

                        <div>

                            {
                                selectedRides.length && selectedRides.length > 1 ?
                                    <RidesActionsButtonRow
                                        rides={selectedRides}
                                        onCallSuccess={handleCallSuccess}
                                        onClearSelection={handleClearSelection}
                                        onRideStatusChangeSuccess={(handleOnMultiRidesStatusChange)}
                                        onDeleteSuccess={handleDeleteRidesSuccess}
                                    />
                                    : null
                            }

                        </div>

                        <div className="flex items-center">
                            <DropzoneDialog
                                isOpen={isBulkUpdateRidesDialogOpen}
                                onOpenChange={handleBulkUpdateRidesDialogStateChange}
                                acceptedFileTypes={acceptedFileTypes}
                                title="Upload Updated Rides"
                                subTitle={null}
                                zoneLabel="Drag and drop file here, or click to select a file."
                                activeZoneLabel="Drop the file here"

                                onSubmit={handleBulkUploadSubmit}
                            >
                                <Button className="mr-2.5">
                                    Bulk Update Rides
                                </Button>
                            </DropzoneDialog>

                            <DropzoneDialog
                                isOpen={isUploadDialogOpen}
                                onOpenChange={handleUploadDialogStateChange}
                                acceptedFileTypes={acceptedFileTypes}
                                title="Upload Rides"
                                subTitle={null}
                                zoneLabel="Drag and drop file here, or click to select a file."
                                activeZoneLabel="Drop the file here"

                                onSubmit={handleSubmit}
                            >
                                <Button>
                                    Upload Rides
                                </Button>
                            </DropzoneDialog>
                        </div>


                    </div>

                    <div className="mt-3 flex items-center justify-between px-1">

                        <div className="flex items-center">


                            <SearchInput value={searchQuery} onChange={handleSearchQuery} className="mr-2.5" />

                            {/* <div className="mx-2.5">

                                <DataTableFacetedFilter options={RIDE_TYPE_OPTIONS} title="Type" selection={rideTypeSelection} setSelection={handleRideTypeSelection} />
                            </div> */}



                            <DataTableFacetedFilter options={RIDE_STATUS_OPTIONS} title="Status" selection={statusSelection} setSelection={setStatusSelection} />

                            <Tooltip disableHoverableContent={!isRefreshingList} >
                                <TooltipTrigger asChild>
                                    <Button variant="ghost" size="icon" className="ml-2 " onClick={handleRefreshList}>
                                        <RefreshCwIcon className={cn(isRefreshingList && "animate-spin")} />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    Refresh List
                                </TooltipContent>
                            </Tooltip>


                        </div>

                        <div className="flex items-center">
                            <DatePickerWithRange onDateRangeSelect={setDateRange} dateRange={dateRange!} />

                            <Button variant="secondary" onClick={handleDateSearch} className="ml-2">Search</Button>
                        </div>

                    </div>





                    <RidesTable
                        list={ridesList}
                        totalCount={totalCount}
                        onPageChange={onPageChange}
                        onRideActionSelect={onRideActionSelect}
                        onRidesSelection={setSelectedRides}
                        setTableRef={(tableRef) => ridesTableRef.current = tableRef}
                        onRowsLimitChange={handleRowsLimitChange}
                        paginationData={pagination}

                    />

                </div>

            </div>

            <UpdateRideDetailsDialog onSuccess={refreshCurrList} />
            {isLoaderVisible && <LoaderWithOverlay />}


        </>


    )
}

export default RidesDashboard;