

import {
    RegistrationFlow,
} from "@ory/kratos-client";



import { useEffect, useState } from "react";

import { isEmpty } from "lodash";

import { Card } from "@/components/ui/card";
import SignUpForm from "@/components/sign-up-form";
import { createUserRegistrationFlow } from "@/lib/helpers/user";
import Loader from "@/components/Loader/Loader";
import { showErrorToast } from "@/lib/helpers/toast";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";


/**
 * Component For Signup View
 */
const SignupView = () => {

    const [registrationFlowData, setRegistrationFlowData] = useState<RegistrationFlow>({} as RegistrationFlow)

    /**
     * Init Registeration Flow
     */
    const initRegisterationFlow = async () => {
        try {
            const res = await createUserRegistrationFlow();
            setRegistrationFlowData(res);
        } catch {
            showErrorToast(DEFAULT_ERROR_MESSAGE)
        }
    }

    useEffect(() => {
        initRegisterationFlow();
    }, [])



    return (


        <>
            <div className='container grid h-svh flex-col items-center justify-center bg-primary-foreground lg:max-w-none lg:px-0'>
                <div className='mx-auto flex w-full flex-col justify-center space-y-2 sm:w-[480px] lg:p-8'>
                    <div className='mb-4 flex items-center justify-center'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='mr-2 h-6 w-6'
                        >
                            <path d='M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3' />
                        </svg>
                        <h1 className='text-xl font-medium'>YCB Caller</h1>
                    </div>
                    <Card className='p-6'>




                        {
                            !isEmpty(registrationFlowData) ?
                                <SignUpForm flowData={registrationFlowData} />
                                :

                                <div className="flex items-center justify-center">
                                    <Loader />
                                </div>

                        }
                        {/* <div className="flex justify-center items-center ">
                            <Loader />
                        </div> */}


                    </Card>
                </div>
            </div>
        </>

    )
}



export default SignupView;