/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import * as React from "react";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { TimePicker12 } from "./time-picker";
import { ControllerRenderProps } from "react-hook-form";
import { convertDateFromJsDate } from "@/lib/helpers/datetime";
import { DATE_TIME_FORMAT_IN_USE } from "@/variables/dates";

interface DateTimePicker {
    onChange?: (d?: Date) => void,
    value?: Date,
    fieldProps?: ControllerRenderProps<any>
}
export const DateTimePicker = ({
    onChange,
    value,
    fieldProps,
}: DateTimePicker) => {
    const [date, setDate] = React.useState<Date>();



    const valToUse = React.useMemo(() => {
        return (fieldProps?.value ? fieldProps.value : value) as Date
    }, [value, fieldProps])



    const displayLabel = React.useMemo(() => {
        return valToUse ? convertDateFromJsDate(valToUse).toFormat(DATE_TIME_FORMAT_IN_USE) : <span>Pick a date and time</span>
    }, [valToUse])



    React.useEffect(() => {
        const convertedDate = convertDateFromJsDate(valToUse)
        if (convertedDate.isValid) {
            setDate(convertedDate.toJSDate())
        } else {
            setDate(undefined);
        }
    }, [valToUse])

    const handleChange = (d?: Date) => {
        if (fieldProps?.onChange) {
            fieldProps.onChange(d)
        } else {
            onChange?.(d);
        }
    }
    /**
     * carry over the current time when a user clicks a new day
     * instead of resetting to 00:00
     */
    const handleSelect = (newDay: Date | undefined) => {
        if (!newDay) return;
        if (!valToUse) {
            handleChange(newDay)
            return;
        }
        const diff = newDay.getTime() - valToUse.getTime();
        const diffInDays = diff / (1000 * 60 * 60 * 24);
        const newDateFull = convertDateFromJsDate(valToUse).plus({ days: Math.ceil(diffInDays) }).toJSDate();
        handleChange(newDateFull);
    };

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "min-w-32 w-full !mx-0 px-1 justify-start text-left font-normal",
                        !valToUse && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {displayLabel}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(d) => handleSelect(d)}
                    initialFocus
                />
                <div className="p-3 border-t border-border">
                    <TimePicker12 setDate={handleChange} date={date} />
                </div>
            </PopoverContent>
        </Popover>
    );
}