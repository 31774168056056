import { MobileSidebar } from "./mobile-sidebar";
import { UserNav } from "./user-nav";
import { cn } from "@/lib/utils"

interface HeaderProps {
    title?: string,
    className?: string,
}


/**
 * Header component for the dashboard
 */
const Header = ({ className }: HeaderProps) => {
    return (

        <header className={cn("sticky inset-x-0 top-0 w-full", className)} >
            <nav className="flex items-center justify-between px-4 py-2 md:justify-end">
                <div className={cn('block lg:!hidden')}>
                    <MobileSidebar />
                </div>
                <div className="flex items-center gap-2">
                    <UserNav />

                </div>
            </nav>
        </header >


    )
}


export default Header;