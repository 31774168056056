import { Card } from "@/components/ui/card"
import { VerificationFlow } from "@ory/kratos-client"
import { useEffect, useState } from "react"
import { isEmpty } from "lodash"
import Loader from "@/components/Loader/Loader"
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast"
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages"
import { getAccountVerificationFlow } from "@/lib/helpers/user"

import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { extractErrorMessageFromOryResponse, extractSuccessMessageFromOryResponse } from "@/lib/ory"
import AccountVerificationForm from "@/components/account-verification-form"



/**
 * Component which shows account verification view
 */
const AccountVerificationView = () => {


    const [verificationFlowData, setVerificationFlowData] = useState<VerificationFlow>({} as VerificationFlow)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()


    /**
     * Init verification flow
     */
    const initVerificationFlow = async () => {
        try {
            const flowId = searchParams.get("flow");


            if (!flowId) {
                navigate("/login");
                return;
            }
            const res = await getAccountVerificationFlow(flowId)


            const errorMsg = extractErrorMessageFromOryResponse(res);
            const successMsg = extractSuccessMessageFromOryResponse(res);
            if (errorMsg) {
                showErrorToast(errorMsg);
                return;
            }

            if (successMsg) {
                showSuccessToast(successMsg);
                setVerificationFlowData(res);
            }

        } catch {
            showErrorToast(DEFAULT_ERROR_MESSAGE)
        }
    }

    /**
     * Redirect user to dashboard after verification
     */
    const onVerificationSuccess = () => {
        navigate("/");
    }

    useEffect(() => {

        initVerificationFlow()
    }, [])
    return (


        <div className='container grid h-svh flex-col items-center justify-center bg-primary-foreground lg:max-w-none lg:px-0'>
            <div className='mx-auto flex w-full flex-col justify-center space-y-2 sm:w-[480px] lg:p-8'>
                <div className='mb-4 flex items-center justify-center'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='currentColor'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='mr-2 h-6 w-6'
                    >
                        <path d='M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3' />
                    </svg>
                    <h1 className='text-xl font-medium'>YCB Caller</h1>
                </div>
                <Card className='p-6'>

                    {
                        !isEmpty(verificationFlowData) ?
                            <AccountVerificationForm flowData={verificationFlowData} onSuccess={onVerificationSuccess} />
                            :

                            <div className="flex justify-center items-center">
                                <Loader />
                            </div>

                    }


                    <p className='mt-4 px-8 text-center text-sm text-muted-foreground'>
                        Already have an account ?{' '}
                        <Link
                            to='/login'
                            className='underline underline-offset-4 hover:text-primary'
                        >
                            Sign In
                        </Link>
                        .
                    </p>
                </Card>
            </div>
        </div>

    )
}



export default AccountVerificationView