/* eslint-disable @typescript-eslint/no-explicit-any */
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import isEmpty from "lodash/isEmpty";
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Check if provided value is undefined
 * @param val Provided value
 *
 */
export const isUndefinedVal = (val: any) => {
  return isEmpty(val);
};

/**
 * This function removes the provided list of properties from the provided object
 * @param obj Object data
 * @param props List of properties to remove
 */
export const removeProp = (obj: Record<string, any>, props: string[]) => {
  if (!Array.isArray(props)) props = [props];
  props.map((prop) => {
    const deepLink = prop.split(".");
    if (deepLink.length > 1) {
      let linked = obj;
      deepLink.forEach((link, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        index === deepLink.length - 1
          ? delete linked[link]
          : // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            (linked = linked[link]);
      });
    } else {
      delete obj[prop];
    }
  });

  return obj;
};

/**
 * This function formats the provided into number into readable format
 * @param phoneNumberString
 * @returns
 */
export const formatPhoneNumberWithCountryCode = (phoneNumberString: string) => {
  // Remove any non-digit characters from the phone number
  const cleanNumber = phoneNumberString.replace(/[^\d+]/g, "");

  // US phone number format (10 digits or 11 with country code +1)
  const usRegex = /^(\+?1)?(\d{3})(\d{3})(\d{4})$/;

  // Indian phone number format (10 digits or 12 with country code +91)
  const indiaRegex = /^(\+?91)?(\d{5})(\d{5})$/;

  let match;
  // First, check for US format
  match = usRegex.exec(cleanNumber);
  if (match) {
    const countryCode = match[1] ? match[1] : "";
    const part1 = match[2];
    const part2 = match[3];
    const part3 = match[4];

    // Return the formatted US phone number
    return `${countryCode ? `+${countryCode} ` : " "}(${part1}) ${part2}-${part3}`;
  }

  // Then, check for Indian format
  match = indiaRegex.exec(cleanNumber);
  if (match) {
    const countryCode = match[1] ? match[1] : "";
    const part1 = match[2];
    const part2 = match[3];

    // Return the formatted Indian phone number
    return `${countryCode ? `+${countryCode} ` : " "}${part1}-${part2}`;
  }

  // Return the original phone number if it doesn't match any format
  return phoneNumberString;
};

/**
 * This helper function converts kebab-case string into snake_case string
 * @param str Kebab case string which will converted to snakecase
 * @returns
 */
export const convertKebabCaseToSnakeCase = (str: string) => {
  return str.replace(/-/g, "_");
};
