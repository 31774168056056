/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    memoryStorage: Record<string, any>;
  }
}
/**
 * Creates a fallback for storing values incase localStorage is not available
 */
if (typeof window !== "undefined") {
  if (window && window.memoryStorage) {
    window.memoryStorage = {};
  }
}

/**
 * Static class which contains helper methods for localStorage
 */
export default class Storage {
  /**
   * Get value with the provided key
   * @param {*} key
   * @returns {*}
   */
  static get(key: string): any {
    let value;
    if (localStorage) {
      value = localStorage.getItem(key) ?? this.getMemoryStorage()[key];
    } else {
      value = this.getMemoryStorage()[key];
    }
    return value || null;
  }
  /**
   * Remove the provided key
   * @param {*} key
   */
  static remove(key: string) {
    localStorage?.removeItem(key);
    if (window.memoryStorage) {
      delete window.memoryStorage[key];
    }
  }
  /**
   * Set value with  the provided key
   * @param {*} key
   */
  static set(key: string, value: any) {
    if (localStorage) {
      localStorage.setItem(key, value);
    } else {
      window.memoryStorage[key] = value;
    }
  }
  /**
   * Clear local storage and fallback object
   */
  static clear() {
    localStorage?.clear();
    window.memoryStorage = {};
  }

  /**
   * Get fallback store object
   */
  static getMemoryStorage() {
    return window.memoryStorage || {};
  }
}
