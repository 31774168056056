import { capitalizeAllFirstLetters } from "@/lib/capitalize";
import { convertKebabCaseToSnakeCase } from "@/lib/utils";
import { RIDE_STATUS_MAP, RideStatus } from "@/typings";

/**
 * List of available ride status
 */
const RIDE_STATUS_VALUES = [
  "confirmed",
  "pending",
  "cancelled",
  "in-progress",
  "no-answer",
  "contacted-support",
];

/**
 * List of ride types
 */
const RIDE_TYPE_VALUES = ["appointment", "return"];
const RIDE_STATUS_WITH_DASH = ["in-progress", "no-answer", "contacted-support"];

/**
 * Map of Ride actions
 *
 * It is used for passing values to the functions
 */
export const RIDE_ACTIONS = {
  CALL: "call",
  CANCEL: "cancel-ride",
  CONFIRM: "confirm-ride",
};

/**
 * Map of Ride status
 */
export const RIDE_STATUS_CONFIG = RIDE_STATUS_VALUES.reduce((accu, s) => {
  const convertedStatus = convertKebabCaseToSnakeCase(s).toUpperCase();
  if (!accu[convertedStatus]) {
    accu[convertedStatus] = s as RideStatus;
  }

  return accu;
}, {} as RIDE_STATUS_MAP);

/**
 * Map of ride status display values
 */
export const RIDE_STATUS_DISPLAY_VALUES = RIDE_STATUS_VALUES.reduce(
  (acc, s) => {
    const sKey = s as RideStatus;
    if (!acc[sKey]) {
      let valueToSet = s;
      if (RIDE_STATUS_WITH_DASH.includes(sKey)) {
        valueToSet = sKey.split("-").join(" ");
      }

      if (sKey === "pending") {
        valueToSet = "Not Confirmed";
      }
      acc[sKey] = valueToSet;
    }
    return acc;
  },
  {} as Record<RideStatus, string>
);

/**
 * Name of the key which is used to store last sync timestamp
 */
export const RIDES_LAST_SYNC_KEY_NAME = "rides-last-sync";

/**
 * Type of variants which can be shown based on ride status
 */
export const RIDE_STATUS_BADGE_VARIANT = {
  cancelled: "destructive",
  confirmed: "default",
  pending: "secondary",
  "in-progress": "info",
  "no-answer": "inactive",
  "contacted-support": "warning",
};

/**
 * List of ride status options
 */
export const RIDE_STATUS_OPTIONS = RIDE_STATUS_VALUES.map((s) => {
  let labelToUse = s;

  if (RIDE_STATUS_WITH_DASH.includes(labelToUse)) {
    labelToUse = labelToUse.split("-").join(" ");
  }

  if (labelToUse === "pending") {
    labelToUse = "Not Confirmed";
  }
  return {
    label: capitalizeAllFirstLetters(labelToUse),
    value: s,
  };
});
/**
 * Created options from ride types
 */
export const RIDE_TYPE_OPTIONS = RIDE_TYPE_VALUES.map((rT) => {
  return {
    label: capitalizeAllFirstLetters(rT),
    value: rT,
  };
});

/**
 * Number of minutes to wait for in-progress call to change ride'sstatus to "No Answer"
 */
export const WAIT_TIME_FOR_CALL_RESPONSE = 2;
