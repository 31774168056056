import { useEffect, useRef } from "react";

/**
 * Custom hook which provides functionality to call the provided callback based on provided interval
 * @param callback Callback function which will called after the provided delay
 * @param delay Number of milliseconds for delay
 */
const useInterval = (
  callback: () => void | Promise<void>,
  delay: number | null
) => {
  const intervalRef = useRef<null | number>(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () =>
        window.clearInterval(intervalRef.current as unknown as NodeJS.Timeout);
    }
  }, [delay]);
  return intervalRef;
};

export default useInterval;
