
import './App.css'
import { ThemeProvider } from './components/theme-provider'
import { Toaster } from "@/components/ui/sonner"
import * as Sentry from "@sentry/react";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import SignupView from './views/sign-up-view';
import LoginView from './views/login-view';
import AccountRecoveryView from './views/account-recovery-view';
import AccountVerificationView from './views/account-verification-view';
import DashboardView from './views/dashboard-view';
import RidesDashboard from './views/child-views/rides-view';
import SettingsView from './views/child-views/settings-view';
import UserProfileView from './views/child-views/user-profile-view';
import UserSecuritySettingsView from "./views/child-views/user-security-view";
import { useGlobalLoaderStore } from "@/lib/hooks/use-global-loader"
import LoaderWithOverlay from './components/Loader/LoaderWithOverlay';
import ErrorView from "@/components/error-view";
import { checkUserSessionAndRedirectToDashboard, checkUserSessionAndRedirectToLogin } from './lib/helpers/routes';
import { TooltipProvider } from './components/ui/tooltip';
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);
const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <DashboardView />,
    loader: checkUserSessionAndRedirectToLogin,
    errorElement: <ErrorView />,
    children: [
      {
        // path: "/rides",
        element: <RidesDashboard />,
        index: true,
      },
      {
        path: "/settings",
        element: <SettingsView />,
        children: [
          {
            // path: "profile",
            element: <UserProfileView />,
            index: true,
          },
          {
            path: "security",
            element: <UserSecuritySettingsView />
          }
        ]
      }
    ],
  },
  {
    path: "/go-to-sign-up",
    loader: checkUserSessionAndRedirectToDashboard,
    element: <SignupView />
  },
  {
    path: "/login",
    loader: checkUserSessionAndRedirectToDashboard,
    element: <LoginView />
  },
  {
    path: "/account-recovery",
    loader: checkUserSessionAndRedirectToDashboard,
    element: <AccountRecoveryView />
  },
  {
    path: "/account-verification",
    loader: checkUserSessionAndRedirectToDashboard,
    element: <AccountVerificationView />
  },
  {
    path: "*",
    element: <ErrorView pageNotFound />
  }
]);
function App() {

  const isLoaderVisible = useGlobalLoaderStore((state) => state.isVisible);

  return (
    <ThemeProvider defaultTheme="light">
      <TooltipProvider>
        <RouterProvider router={router} />

        {isLoaderVisible && <LoaderWithOverlay />}
        <Toaster />
      </TooltipProvider>

    </ThemeProvider>
  )
}

export default App
