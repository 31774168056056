export function capitalizeFirstLetter(str: string) {
  if (str == null && str === undefined) {
    return "";
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
export function capitalizeAllFirstLetters(str: string, separator = " ") {
  if (str == null || str === undefined) {
    return "";
  } else {
    return str
      .split(separator)
      .map((item) => {
        return item.charAt(0).toUpperCase() + item.slice(1);
      })
      .join(" ");
  }
}
export function capitalizeFirstLetterOnly(str: string) {
  if (str === null || str === undefined) {
    return "";
  } else {
    return (
      str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    );
  }
}
