/* eslint-disable @typescript-eslint/no-misused-promises */

import { Input } from "@/components/ui/input";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { AxiosError } from "axios";
import { extractCsrfTokenFromOryResponse, extractErrorMessageFromOryResponse } from "@/lib/ory";
import {
    LoginFlow,
} from "@ory/kratos-client";
import { extractUserDataFromSessionData, loginUser } from "@/lib/helpers/user";
import { Link, useNavigate } from "react-router-dom";

import { Button } from '@/components/custom/button'
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { HTMLAttributes } from "react";
import { cn } from "@/lib/utils";
import { PasswordInput } from "./custom/password-input";
import { useUserStore } from "@/lib/hooks/use-user";
import { showErrorToast } from "@/lib/helpers/toast";
import useLoader from "@/lib/hooks/use-loader";



/**
 * Login Form Schema 
 */
const formSchema = z.object({
    email: z.string({ "required_error": "Email is required" }).trim().min(1, "Email is required").email("Email is not valid"),
    password: z.string({ "required_error": "Password is required" }).trim().min(1, "Password is required"),


})


/**
 * Type def for LoginForm
 */
interface LoginFormProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * Login Flow data 
     */
    flowData: LoginFlow,

}



const LoginForm = ({ flowData, className, ...props }: LoginFormProps) => {



    const { isLoaderVisible, setLoaderVisible } = useLoader(false);

    const { setUser, setUserSessionData } = useUserStore()

    const navigate = useNavigate();
    /**
     * Form created by react-hook-form
     */
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: "",
            password: ""
        },

    })


    /**
      * This function is triggered when form is submitted 
      * @param values Form Values
      */
    async function onSubmit(values: z.infer<typeof formSchema>) {

        try {

            setLoaderVisible(true);
            const csrfToken = extractCsrfTokenFromOryResponse(flowData);
            const userloginData = { ...values, csrfToken };
            const res = await loginUser({ ...userloginData, csrfToken }, flowData.id)
            setUser(extractUserDataFromSessionData(res.session));
            setUserSessionData(res.session)

            setLoaderVisible(false);
            navigate("/");

        } catch (error) {
            let errorMessage = DEFAULT_ERROR_MESSAGE
            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as LoginFlow;
                errorMessage = extractErrorMessageFromOryResponse(errorResponse);
            }
            showErrorToast(errorMessage)
            setLoaderVisible(false);
        }

    }

    return (



        <div className={cn('grid gap-6', className)} {...props}>

            <div className='flex flex-col space-y-2 text-left'>
                <h1 className='text-2xl font-semibold tracking-tight'>Login</h1>
                <p className='text-sm text-muted-foreground'>
                    Enter your email and password below <br />
                    to log into your account


                </p>


            </div>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className='grid gap-2'>
                        <FormField
                            control={form.control}
                            name='email'
                            render={({ field }) => (
                                <FormItem className='space-y-1'>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder='name@example.com' {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='password'
                            render={({ field }) => (
                                <FormItem className='space-y-1'>
                                    <div className='flex items-center justify-between'>
                                        <FormLabel>Password</FormLabel>
                                        <Link
                                            to='/account-recovery'
                                            className='text-sm font-medium text-muted-foreground hover:opacity-75'
                                        >
                                            Forgot password?
                                        </Link>
                                    </div>
                                    <FormControl>
                                        <PasswordInput placeholder='********' {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />


                        {/* <p className="mt-1 text-sm text-muted-foreground">
                            Don&apos;t have an account?{' '}
                            <Link
                                to='/sign-up'
                                className='underline underline-offset-4 hover:text-primary'
                            >
                                Create here
                            </Link>
                        </p> */}
                        <Button className='mt-2' loading={isLoaderVisible}>
                            Login
                        </Button>



                    </div>
                </form>
            </Form>
        </div>
    )
}



export default LoginForm;