import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from '../ui/button';
import Typography from '../typography';
import { AcceptedFileFormats } from './accepted-file-formats';
import { cn } from "@/lib/utils"
import { FileText } from 'lucide-react';




/**
 * 
 */
type OnSubmit = (files: File[]) => void | Promise<void>
interface DropzoneDialogProps {
    /**
     * Button node which will be used to open dialog
     */
    children: ReactNode,
    /**
     * Title for the dropzone
     */
    title?: string,
    /**
     * Subtitle for the dropzone
     */
    subTitle?: string | null,
    /**
     * Inactive dropzone label 
     */
    zoneLabel?: string,
    /**
     * Active dropzone 
     */
    activeZoneLabel?: string,
    /**
     * Map of Accepted file formats
     */
    acceptedFileTypes: Accept,
    /**
     * Optional callback function which is triggered when drop is successfull
     * @param acceptedFiles List of accpeted files
     * @returns 
     */
    onDrop?: (acceptedFiles: File[]) => void,
    /**
     * Callback when submit button is clicked
     */
    onSubmit?: OnSubmit,
    /**
     * Marks if dialog is open or not
     */
    isOpen: boolean,
    /**
     * Callback to handle dialog state change
     * @param isOpen Boolean value to mark state of dialog
     */
    onOpenChange: (isOpen: boolean) => void,
    /**
     * Label text for the submit button
     */
    submitBtnText?: string,
}

/**
 * Dialog component which allows uploading of files
 */
const DropzoneDialog = ({
    isOpen,
    onOpenChange,
    children,
    title = "Upload Files",
    subTitle = "Drag and drop files here, or click to select files.",
    zoneLabel = "Drag & drop some files here, or click to select files",
    activeZoneLabel = "Drop the files here...",
    submitBtnText = "Upload",
    acceptedFileTypes,
    onDrop,
    onSubmit,
}: DropzoneDialogProps) => {
    const [files, setFiles] = useState<File[]>([])
    // Callback to handle dropped files
    const handleDrop = useCallback((acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
        onDrop?.(acceptedFiles);


    }, [onDrop]);


    useEffect(() => {
        // Clear files state when dialog is closed
        if (!isOpen) {
            setFiles([])
        }
    }, [isOpen])


    /**
     * Handles submit button click
     */
    const handleSubmit = useCallback(() => {

        onSubmit?.(files);

    }, [onSubmit, files])

    // Dropzone hook
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: acceptedFileTypes
    });



    /**
     * List of file names extracted from dropped files
     */
    const fileNames = useMemo(() => {
        return files.map((file) => {
            return (
                <Typography
                    key={file.name}
                    variant="b2-medium"
                    className="mt-2.5 leading-[1.428] text-black"
                >
                    {file.name}
                </Typography>
            );
        })
    }, [files]);


    /**
     * List of accepted file formats
     */
    const acceptedFileFormats = useMemo(() => {
        const typeKeys = Object.keys(acceptedFileTypes);
        return typeKeys.reduce((acc, k) => {
            if (acceptedFileTypes[k]) {
                acc.push(...acceptedFileTypes[k])
            }
            return acc;
        }, [] as string[])
    }, [acceptedFileTypes])




    return (
        <div>
            {/* Button to open the dialog */}
            <Dialog open={isOpen} onOpenChange={onOpenChange}>
                <DialogTrigger asChild>
                    {children}

                </DialogTrigger>

                {/* Dialog content */}
                <DialogContent>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogDescription>
                        {subTitle}
                    </DialogDescription>

                    {/* Dropzone component */}
                    <div
                        {...getRootProps()}
                        className={cn(`mt-4 cursor-pointer flex flex-col items-center justify-center rounded-lg border-2 border-dashed p-6`, isDragActive ? 'border-blue-400' : 'border-gray-300')}
                    >
                        <input {...getInputProps()} />


                        {

                            !fileNames.length ?


                                <>
                                    {
                                        isDragActive ? (
                                            <p className="text-blue-400">{activeZoneLabel}</p>
                                        ) : (
                                            <p className="text-gray-500">{zoneLabel}</p>
                                        )
                                    }

                                    <AcceptedFileFormats acceptedFileFormats={acceptedFileFormats} />
                                </>

                                :
                                <>

                                    <FileText className='size-8' />


                                    {fileNames[0]}


                                </>

                        }


                    </div>


                    <DialogFooter>


                        {/* <DialogClose asChild>

                            <Button variant="destructive" className="mt-4 px-4 py-2">
                                Close
                            </Button>

                        </DialogClose> */}

                        <Button className='mt-4 px-4 py-2' onClick={handleSubmit}>
                            {submitBtnText}
                        </Button>
                    </DialogFooter>

                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DropzoneDialog;
