/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react-refresh/only-export-components */

import * as React from "react"
import {
    ColumnDef,
    PaginationState,
    Table as ReactTable,
    // ColumnFiltersState,
    // SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    // getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import DateTime from "@/lib/helpers/datetime";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    // ArrowUpDown, 
    // ChevronDown, 
    MoreHorizontal
} from "lucide-react"

import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
    DropdownMenu,
    // DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
// import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { formatPhoneNumberWithCountryCode } from "@/lib/utils";
import { DATE_TIME_FORMAT_IN_USE } from "@/variables/dates";
import type { OnRideActionSelectFunc, RideAction, RideDataItem, RideItemList, RideStatus } from "@/typings"
import TableRowsLimitSelector from "./table-rows-limit-selector";
import { RIDE_ACTIONS, RIDE_STATUS_BADGE_VARIANT, RIDE_STATUS_CONFIG, RIDE_STATUS_DISPLAY_VALUES } from "@/variables/rides";
import { Badge, BadgeVariants } from "./ui/badge";
import { ScrollArea } from "./ui/scroll-area";
import { getRideStatusFromRideData } from "@/lib/helpers/rides";
import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from "@radix-ui/react-icons";
import { useEditRideDetailsStore } from "@/stores/edit-ride-details.ts";



// const data: RideDataItem[] = [
//     {
//         "_id": "66fe2b929219f049679d488b",
//         "tripId": "16897825",
//         "customerName": "PANLILIO, RUBEN R",
//         "phone": "5106712192",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:20:00.000Z",
//         "pickUpTime": "7:20 AM",
//         "pickUpAddress": "636 Lincoln Ave",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.577Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d488c",
//         "tripId": "16897827",
//         "customerName": "PANLILIO, RUBEN R",
//         "phone": "5103524011",
//         "pickUpDate": "9/27/2024",
//         "type": "return",
//         "pickUpDateAndTime": "2024-09-27T19:00:00.000Z",
//         "pickUpTime": "12:00 PM",
//         "pickUpAddress": "801 Davis St",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.577Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d488d",
//         "tripId": "16897835",
//         "customerName": "SEKHON, KULWANT S",
//         "phone": "5104890195",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T15:15:00.000Z",
//         "pickUpTime": "8:15 AM",
//         "pickUpAddress": "4723 Loretta Way",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.577Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d488e",
//         "tripId": "16897837",
//         "customerName": "SEKHON, KULWANT S",
//         "phone": "5104896996",
//         "pickUpDate": "9/27/2024",
//         "type": "return",
//         "pickUpDateAndTime": "2024-09-27T19:15:00.000Z",
//         "pickUpTime": "12:15 PM",
//         "pickUpAddress": "32930 Alvarado Niles Rd",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.577Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d488f",
//         "tripId": "16897845",
//         "customerName": "SCOTT, GLORIA J",
//         "phone": "5106058528",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T16:25:00.000Z",
//         "pickUpTime": "9:25 AM",
//         "pickUpAddress": "4433 Sequoyah Rd",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4890",
//         "tripId": "16897847",
//         "customerName": "CARBAJAL RIVERA, RODOLFO",
//         "phone": "5103294325",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T12:30:00.000Z",
//         "pickUpTime": "5:30 AM",
//         "pickUpAddress": "165 Lund Ave",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4891",
//         "tripId": "16897849",
//         "customerName": "CHUNG NGAN, LAI",
//         "phone": "5103056688",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T15:00:00.000Z",
//         "pickUpTime": "8:00 AM",
//         "pickUpAddress": "47003 Mission Falls Ct",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4892",
//         "tripId": "16897885",
//         "customerName": "SMITH JR, BRIAN L",
//         "phone": "2094501643",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T13:15:00.000Z",
//         "pickUpTime": "6:15 AM",
//         "pickUpAddress": "359 S Washington St",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4893",
//         "tripId": "16897897",
//         "customerName": "ARBABI, TAHEREH",
//         "phone": "7144833610",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:12:00.000Z",
//         "pickUpTime": "7:12 AM",
//         "pickUpAddress": "155 S Festival Dr",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4894",
//         "tripId": "16897899",
//         "customerName": "ELLIS, LORRAINE P",
//         "phone": "8054417663",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:10:00.000Z",
//         "pickUpTime": "7:10 AM",
//         "pickUpAddress": "209 Santa Maria",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4895",
//         "tripId": "16897903",
//         "customerName": "YORK, NATHANIEL",
//         "phone": "5108662532",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:30:00.000Z",
//         "pickUpTime": "7:30 AM",
//         "pickUpAddress": "3024 Fruitvale Ave",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4896",
//         "tripId": "16897907",
//         "customerName": "ABERAN-TABRIZI, SHOKOFEH",
//         "phone": "6572598892",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:15:00.000Z",
//         "pickUpTime": "7:15 AM",
//         "pickUpAddress": "16092 Craig Ln",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4897",
//         "tripId": "16897915",
//         "customerName": "HOPKINS, TROY",
//         "phone": "5303230690",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T11:40:00.000Z",
//         "pickUpTime": "4:40 AM",
//         "pickUpAddress": "3431 Spruce Ave",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4898",
//         "tripId": "16897917",
//         "customerName": "RILEY, STANLEY",
//         "phone": "5103624593",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T18:45:00.000Z",
//         "pickUpTime": "11:45 AM",
//         "pickUpAddress": "7607 International Blvd",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d4899",
//         "tripId": "16897919",
//         "customerName": "HOPKINS, TROY",
//         "phone": "5302915345",
//         "pickUpDate": "9/27/2024",
//         "type": "return",
//         "pickUpDateAndTime": "2024-09-27T13:30:00.000Z",
//         "pickUpTime": "6:30 AM",
//         "pickUpAddress": "6390 Runnymeade Dr",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d489a",
//         "tripId": "16897923",
//         "customerName": "ROSENBLATT, DAVID",
//         "phone": "5108807477",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:20:00.000Z",
//         "pickUpTime": "7:20 AM",
//         "pickUpAddress": "574 Aileen St",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d489b",
//         "tripId": "16897929",
//         "customerName": "EGUINA, MICHAEL P",
//         "phone": "6284884160",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T18:45:00.000Z",
//         "pickUpTime": "11:45 AM",
//         "pickUpAddress": "389 Mcleod St",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d489c",
//         "tripId": "16897931",
//         "customerName": "WILEY, LADONNA T",
//         "phone": "5105006786",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T19:25:00.000Z",
//         "pickUpTime": "12:25 PM",
//         "pickUpAddress": "41190 Fremont Blvd",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d489d",
//         "tripId": "16897933",
//         "customerName": "JACKSON, DAVON",
//         "phone": "5105751600",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T14:25:00.000Z",
//         "pickUpTime": "7:25 AM",
//         "pickUpAddress": "2595 Depot Rd",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     },
//     {
//         "_id": "66fe2b929219f049679d489e",
//         "tripId": "16897935",
//         "customerName": "BRIAR, CYNTHIA",
//         "phone": "5102098383",
//         "pickUpDate": "9/27/2024",
//         "type": "appointment",
//         "pickUpDateAndTime": "2024-09-27T18:30:00.000Z",
//         "pickUpTime": "11:30 AM",
//         "pickUpAddress": "1089 Bluebell Dr",
//         "status": "pending",
//         "createdAt": "2024-10-03T05:28:50.578Z"
//     }

// ]

export const columns: ColumnDef<RideDataItem>[] = [
    {
        id: "select",
        header: ({ table }) => (
            <Checkbox
                checked={
                    table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected() && "indeterminate")
                }
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
            />
        ),
        cell: ({ row }) => {

            return (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label="Select row"
                />
            )
        },
        enableSorting: false,
        enableHiding: false,
    },
    {
        accessorKey: "tripId",
        header: () => <div className="">Trip ID</div>,
        cell: ({ row }) => {


            return <div className=" font-medium">{row.getValue("tripId")}</div>
        },
    },
    {
        accessorKey: "type",
        header: "Type",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("type")}</div>
        ),
    },
    {
        accessorKey: "customerName",
        header: () => {
            return (

                <div className="">Customer Name</div>
                // <Button
                //     variant="ghost"
                //     onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                // >
                //     Customer Name
                //     <ArrowUpDown className="ml-2 h-4 w-4" />
                // </Button>
            )
        },
        cell: ({ row }) => <div className="capitalize">{row.getValue("customerName")}</div>,
    },
    {
        accessorKey: "phone",
        header: () => <div className="">Customer Phone</div>,
        cell: ({ row }) => {
            const rideData = row.original;
            const getFormattedPhoneNumber = () => {
                const customerPhone = rideData.phone
                return customerPhone ? formatPhoneNumberWithCountryCode(customerPhone) : "N/A"
            }

            return <div className="font-medium">{getFormattedPhoneNumber()}</div>
        },
    },


    {
        accessorKey: "insurance",
        header: () => <div className="">Insurance</div>,
        cell: ({ row }) => {
            const insuranceName: string = row.getValue("insurance");
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const cachedInsuranceName = React.useMemo(() => {
                const normalisedInsuranceName = insuranceName?.trim()
                return normalisedInsuranceName ? normalisedInsuranceName : "N/A"
            }, [insuranceName])
            return <div className="font-medium">{cachedInsuranceName}</div>
        },
    },

    {
        accessorKey: "pickUpDateAndTime",
        header: () => <div className="">Pick Up Date and Time</div>,
        cell: ({ row }) => {
            const dateTimeStr: string = row.getValue("pickUpDateAndTime")
            const formattedStr = DateTime.fromISO(dateTimeStr).toFormat(DATE_TIME_FORMAT_IN_USE);
            return <div className=" font-medium">{formattedStr}</div>
        },
    },


    {
        accessorKey: "pickUpAddress",
        header: () => <div className="">Pick Up Address</div>,
        cell: ({ row }) => {


            return <div className="font-medium">{row.getValue("pickUpAddress")}</div>
        },
    },

    {
        accessorKey: "status",
        header: () => <div className="">Status</div>,
        cell: ({ row }) => {
            const rideStatus = getRideStatusFromRideData({
                status: row.getValue("status"),
                callStartedAt: row.original.callStartedAt,
            } as RideDataItem);
            const getBadgeVariant = (status: RideStatus) => {
                return RIDE_STATUS_BADGE_VARIANT[status];
            }
            return (
                <Badge variant={getBadgeVariant(rideStatus) as BadgeVariants["variant"]} className="font-medium capitalize">
                    {RIDE_STATUS_DISPLAY_VALUES[rideStatus]}
                </Badge>
            )
        },
    },

    {
        id: "actions",
        enableHiding: false,
        cell: ({ row, table }) => {
            const rideData = row.original;
            const rideId = rideData._id!;
            const opts = table.options.meta as {
                onRideActionSelect: OnRideActionSelectFunc,
                openEditRideDetailsDialog: (rideData: RideDataItem) => void
            }

            const currRideStatus = getRideStatusFromRideData(rideData)


            const isCallInProgress = currRideStatus === RIDE_STATUS_CONFIG.IN_PROGRESS;
            const isContactedSupport = currRideStatus === RIDE_STATUS_CONFIG.CONTACTED_SUPPORT;
            const isRideConfirmed = rideData.status === "confirmed";
            const isRideCancelled = rideData.status === "cancelled";

            const isPhoneCallAvailable = !rideData.phone?.trim() || isRideConfirmed || isRideCancelled || isCallInProgress || isContactedSupport
            return (
                <DropdownMenu >
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="size-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="size-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem disabled={isPhoneCallAvailable} onSelect={() => opts.onRideActionSelect(rideId, RIDE_ACTIONS.CALL as RideAction)}>Call for Confirmation</DropdownMenuItem>
                        <DropdownMenuItem disabled={isRideConfirmed} onSelect={() => opts.onRideActionSelect(rideId, RIDE_ACTIONS.CONFIRM as RideAction)}>Mark as Confirmed</DropdownMenuItem>
                        <DropdownMenuItem disabled={isRideCancelled} onSelect={() => opts.onRideActionSelect(rideId, RIDE_ACTIONS.CANCEL as RideAction)}>Mark as Cancelled</DropdownMenuItem>
                        <DropdownMenuItem onSelect={() => opts.openEditRideDetailsDialog(row.original)}>Update Ride</DropdownMenuItem>
                        <DropdownMenuItem onSelect={() => opts.onRideActionSelect(rideId, "delete-ride")}>Remove Ride</DropdownMenuItem>

                    </DropdownMenuContent>
                </DropdownMenu>
            )
        },
    },
]


interface RidesTableProps {
    /**
     * List of rides
     */
    list: RideItemList,
    /**
     * Total count of rides
     */
    totalCount: number,
    /**
     * Callback to handle page change
     * @param pageIndex 
     * @returns 
     */
    onPageChange: (pageIndex: number) => void | Promise<void>,
    /**
     * Callback to handle selected action on ride
     */
    onRideActionSelect: OnRideActionSelectFunc
    /**
     * Handle rows selection
     */
    onRidesSelection?: ((selectedRides: RideItemList) => void) | ((selectedRides: RideItemList) => Promise<void>)
    /**
     * Callback which gets called on mount and sends table ref
     * @param table 
     */
    setTableRef?: (table: ReactTable<RideDataItem>) => void;
    /**
     * Pagination Data for the table
     */
    paginationData: PaginationState,
    /**
     * Handle page rows limit change
     * @param pageLimit 
     * @returns 
     */
    onRowsLimitChange?: (pageLimit: number) => void | Promise<void>,
}

const RidesTable = ({
    list, paginationData: pagination,
    totalCount,
    onRideActionSelect,
    onRidesSelection,
    onPageChange,
    onRowsLimitChange,
    setTableRef
}: RidesTableProps) => {
    // const [sorting, setSorting] = React.useState<SortingState>([])
    // const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    //     []
    // )

    const openEditDialog = useEditRideDetailsStore((state) => state.openEditDialog)
    const [columnVisibility, setColumnVisibility] =
        React.useState<VisibilityState>({})
    /**
     * State to manage row selection
     */
    const [rowSelection, setRowSelection] = React.useState({})




    const openEditRideDetailsDialog = React.useCallback((rideData: RideDataItem) => {
        openEditDialog(rideData);
    }, [openEditDialog])
    /**
     * Table created by react-table hook
     */
    const table = useReactTable({
        data: list,
        columns,
        // onSortingChange: setSorting,
        // onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        manualPagination: true,
        // onPaginationChange: setPagination,
        rowCount: totalCount,
        getRowId(rowData) {
            return rowData._id!;
        },
        state: {
            // sorting,
            // columnFilters,
            columnVisibility,
            rowSelection,
            pagination,
        },
        meta: {
            onRideActionSelect,
            openEditRideDetailsDialog
        },
        initialState: {

            columnVisibility: {
                callStartedAt: true,
            },
            //...
        }
    })

    const totalTablePageCount = table.getPageCount();

    React.useEffect(() => {
        // Send table object created by hook to parent component on mount
        setTableRef?.(table)
    }, [])

    /**
     * Handle page limit change
     * @param limit 
     */
    const handlePageRowsLimitChange = (limit: string) => {

        onRowsLimitChange?.(Number(limit))
    }

    /**
     * Send selected rides  when rows are selected 
     */
    React.useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original)
        onRidesSelection?.(selectedRows)
    }, [rowSelection])


    /**
     * Cached total page count
     */
    const totalPages = React.useMemo(() => {
        const pageCount = totalTablePageCount
        return pageCount !== 0 ? pageCount : 1
    }, [totalTablePageCount])

    return (

        <>
            <div className="mt-4 min-h-0 min-w-0 flex-1">

                <div className="size-full">

                    <ScrollArea className=" h-full border">
                        <Table className="">
                            <TableHeader className="">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead key={header.id} className="py-2.5">
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </TableHead>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody className="">
                                {table.getRowModel().rows?.length ? (

                                    <>

                                        {table.getRowModel().rows.map((row, index) => (
                                            <TableRow
                                                key={`table-row-index-${index}`}
                                                data-state={row.getIsSelected() && "selected"}

                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell key={cell.id} className="py-2.5">
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}



                                    </>




                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            className="h-24 text-center"
                                        >
                                            No rides.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </ScrollArea>

                </div>


            </div>


            <div className="flex items-center justify-end space-x-2 py-4">

                <div className="space-x-2">


                    <div className="flex ">


                        <div className="mr-2">
                            <TableRowsLimitSelector rowsLimit={pagination.pageSize.toString()} onRowsLimitChange={handlePageRowsLimitChange} />
                        </div>


                        <div className='flex w-[100px] items-center justify-center text-sm font-medium'>
                            Page {table.getState().pagination.pageIndex + 1} of{' '}
                            {totalPages}
                        </div>



                        <div className='flex items-center space-x-2'>
                            <Button
                                variant='outline'
                                className='hidden h-8 w-8 p-0 lg:flex'
                                onClick={() => onPageChange?.(0)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <span className='sr-only'>Go to first page</span>
                                <DoubleArrowLeftIcon className='h-4 w-4' />
                            </Button>
                            <Button
                                variant='outline'
                                className='h-8 w-8 p-0'
                                onClick={() => onPageChange?.(table.getState().pagination.pageIndex - 1)}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <span className='sr-only'>Go to previous page</span>
                                <ChevronLeftIcon className='h-4 w-4' />
                            </Button>
                            <Button
                                variant='outline'
                                className='h-8 w-8 p-0'
                                onClick={() => onPageChange?.(table.getState().pagination.pageIndex + 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                <span className='sr-only'>Go to next page</span>
                                <ChevronRightIcon className='h-4 w-4' />
                            </Button>
                            <Button
                                variant='outline'
                                className='hidden h-8 w-8 p-0 lg:flex'
                                onClick={() => onPageChange?.(table.getPageCount() - 1)}
                                disabled={!table.getCanNextPage()}
                            >
                                <span className='sr-only'>Go to last page</span>
                                <DoubleArrowRightIcon className='h-4 w-4' />
                            </Button>
                        </div>
                    </div>

                </div>
            </div >
        </>

    )
}


export default RidesTable