/* eslint-disable @typescript-eslint/no-misused-promises */
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/custom/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'

import { zodResolver } from '@hookform/resolvers/zod'
import { createUserSettingsFlow, submitUserSettingsFlowData } from '@/lib/helpers/user'
import { extractCsrfTokenFromOryResponse, extractErrorMessageFromOryResponse } from '@/lib/ory'
import { RawUpdateUserSettingsData } from '@/typings'
import { AxiosError } from 'axios'
import { SettingsFlow } from '@ory/kratos-client'
import { showErrorToast, showSuccessToast } from '@/lib/helpers/toast'
import { useUserStore } from '@/lib/hooks/use-user'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { useGlobalLoaderStore } from '@/lib/hooks/use-global-loader'

/**
 * Schema for profile form
 */
const profileFormSchema = z.object({
    firstName: z
        .string()
        .min(1, {
            message: 'First Name is required',
        }),
    email: z
        .string({
            required_error: 'Email is required',
        })
        .email(),
    lastName: z
        .string()
        .min(1, {
            message: 'Last Name is required',
        }),
})

/**
 * Type defs for form
 */
type ProfileFormValues = z.infer<typeof profileFormSchema>

// This can come from your database or API.
const defaultValues: Partial<ProfileFormValues> = {
    firstName: "",
    lastName: "",
    email: "",

}

/**
 * Component which shows user profile form
 */
const UserProfileForm = () => {


    const { user, setUser } = useUserStore()

    const setLoaderVisible = useGlobalLoaderStore((state) => state.setLoaderVisible);

    /**
     * Setup form
     */
    const form = useForm<ProfileFormValues>({
        resolver: zodResolver(profileFormSchema),
        defaultValues: defaultValues,
        mode: 'onChange',
    })

    useEffect(() => {
        if (!isEmpty(user)) {
            form.reset(user)
        }
    }, [user])

    /**
     * Submit the provided values to indentity service
     */
    const onSubmit = async (data: ProfileFormValues) => {
        try {

            setLoaderVisible(true);
            const settingsFlowData = await createUserSettingsFlow();

            const profileUpdateData = {
                csrfToken: extractCsrfTokenFromOryResponse(settingsFlowData),
                method: "profile",
                profileData: {
                    ...data,
                    role: user.role || "dispatcher"
                }

            }

            await submitUserSettingsFlowData(profileUpdateData as RawUpdateUserSettingsData, settingsFlowData.id);

            setUser({ ...data })
            showSuccessToast("Changed saved")
        } catch (error) {
            let errorMsg = ""
            if ((error as AxiosError).response?.status) {
                if ((error as AxiosError).response?.status === 400) {
                    const errorResponse = (error as AxiosError).response?.data as SettingsFlow;
                    errorMsg = extractErrorMessageFromOryResponse(errorResponse);
                }
                if (!errorMsg && (error as AxiosError).response?.status === 403) {
                    errorMsg = "Please re-login to make changes!";
                }
            }


            showErrorToast(errorMsg)
        } finally {
            setLoaderVisible(false);
        }
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
                <FormField
                    control={form.control}
                    name='firstName'
                    rules={{ required: true }}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl>
                                <Input placeholder='Enter your first name' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name='lastName'
                    rules={{ required: true }}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Last Name</FormLabel>
                            <FormControl>
                                <Input placeholder='Enter your last name' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name='email'
                    rules={{ required: true }}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Email</FormLabel>
                            <FormControl >
                                <Input placeholder='Enter your email' {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button type='submit'>Update profile</Button>
            </form>
        </Form>
    )
}


export default UserProfileForm