import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/lib/utils'
import Loader from '../Loader/Loader'

const buttonVariants = cva(
    'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default:
                    'bg-primary text-primary-foreground shadow hover:bg-primary/90',
                destructive:
                    'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
                outline:
                    'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
                secondary:
                    'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
            },
            size: {
                default: 'h-9 px-4 py-2',
                sm: 'h-8 rounded-md px-3 text-xs',
                lg: 'h-10 rounded-md px-8',
                icon: 'size-9',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)

interface ButtonPropsBase
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> { }

type ButtonProps = ButtonPropsBase &
    (
        | { asChild: true }
        | {
            asChild?: false
            loading?: boolean
            leftSection?: JSX.Element
            rightSection?: JSX.Element,
            loaderClassName?: string
        }
    )

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, variant, size, children, ...props }, ref) => {
        const { asChild, ...rest } = props
        if (asChild) {
            return (
                <Slot
                    className={cn(buttonVariants({ variant, size, className }))}
                    ref={ref}
                    {...rest}
                >
                    {children}
                </Slot>
            )
        }

        const {
            loading = false,
            leftSection,
            rightSection,
            disabled,
            loaderClassName,
            ...otherProps
        } = props

        return (
            <button
                className={cn(buttonVariants({ variant, size, className }))}
                disabled={loading || disabled}
                ref={ref}
                {...otherProps}
            >
                {((leftSection && loading) ??
                    (!leftSection && !rightSection && loading)) && (
                        <Loader type='pulse' color='white' size={6} className={cn('mr-2', loaderClassName)} />
                    )}
                {!loading && leftSection && <div className='mr-2'>{leftSection}</div>}
                {children}
                {!loading && rightSection && <div className='ml-2'>{rightSection}</div>}
                {rightSection && loading && (
                    <Loader className={cn('ml-2', loaderClassName)} />
                )}
            </button>
        )
    }
)
Button.displayName = 'Button'

// eslint-disable-next-line react-refresh/only-export-components
export { Button, buttonVariants }