
import { Button } from "../custom/button"
import { DialogFooter, DialogClose } from "../ui/dialog"
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import isMobilePhone from "validator/lib/isMobilePhone"
import { Input } from "../ui/input"
import { Textarea } from "@/components/ui/textarea.tsx";
import { useEffect } from "react"
import { isEmpty } from "lodash"
import { GenericReqResponse, UpdatableRideData } from "@/typings"
import { DateTimePicker } from "../custom/date-time-picker/date-time-picker"
import { convertDateFromJsDate, getDateFromISOWithLocalZone } from "@/lib/helpers/datetime"
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast"
import { updateRideDetails } from "@/lib/helpers/rides"
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages"
import { extractErrorResponseFromHTTPRequest } from "@/lib/http"
import { AxiosError } from "axios"
import { useGlobalLoaderStore } from "@/lib/hooks/use-global-loader"
import { ISO_DATE_TIME_FORMAT_IN_USE } from "@/variables/dates"

/**
 * Schema for ride details form
 */
const formSchema = z.object({
    customerName: z.string().min(1, {
        message: 'Customer Name is required',
    }),
    phone: z.string().refine(isMobilePhone, "Invalid Phone number"),
    pickUpDateAndTime: z.date(),
    pickUpAddress: z.string().min(1, {
        message: "Pickup address is required"
    }),

})

/**
 * Initial form details
 */
const defaultValues = {
    customerName: "",
    phone: "",
    pickUpAddress: "",
    pickUpDateAndTime: undefined,
}

type EditRideDetailsValues = z.infer<typeof formSchema>

/**
 * Type defs of props for UpdateRideDetailsForm 
 */
interface UpdateRideDetailsFormProps {
    /**
     * Shows current state of dialog
     */
    isOpen: boolean,
    /**
     * Stored ride data which is used to prefill form
     */
    storedRideData: UpdatableRideData,
    /**
     * Callback called when dialog is closed by cancel button
     */
    onClose: () => void,
    /**
     * Optional callback function which is called when ride data is updated 
     * @param rideId Id of ride
     * @param updatedRideData Updated ride data
     */
    onSuccess?: (rideId: string, updatedRideData: Partial<UpdatableRideData>) => void,
}
const UpdateRideDetailsForm = ({ isOpen, storedRideData, onSuccess, onClose }: UpdateRideDetailsFormProps) => {

    /**
     * Global loader toggle function extracted from global loader state
     */
    const setLoaderVisible = useGlobalLoaderStore((state) => state.setLoaderVisible)
    /**
       * Form created by react-hook-form
       */
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            ...defaultValues
        },
    })



    useEffect(() => {

        // Prefill form when dialog is opened 
        if (isOpen && !isEmpty(storedRideData)) {
            form.reset({
                customerName: storedRideData.customerName,
                phone: storedRideData.phone,
                pickUpAddress: storedRideData.pickUpAddress,
                pickUpDateAndTime: getDateFromISOWithLocalZone(storedRideData.pickUpDateAndTime, true) as Date,
            })
        } else {
            // Reset form on close
            form.reset()
        }
    }, [isOpen, storedRideData])


    /**
     * Submit edited values 
     * @param values Form values
     */
    const handleSubmit = async (values: EditRideDetailsValues) => {
        try {


            setLoaderVisible(true);
            const convertedDate = convertDateFromJsDate(values.pickUpDateAndTime)
                .setZone(import.meta.env.VITE_APP_TIMEZONE, { keepLocalTime: true })
                .toFormat(ISO_DATE_TIME_FORMAT_IN_USE)
            const updatedRideData: UpdatableRideData = {
                ...values,
                pickUpDateAndTime: convertedDate
            }

            await updateRideDetails(storedRideData._id!, { ...updatedRideData })
            onSuccess?.(storedRideData._id!, updatedRideData)

            showSuccessToast("Ride Updated");

            onClose()
        } catch (error) {
            const errorResponseMsg = extractErrorResponseFromHTTPRequest(error as AxiosError<GenericReqResponse>)?.message;
            showErrorToast(errorResponseMsg || DEFAULT_ERROR_MESSAGE);
        } finally {
            setTimeout(() => {
                setLoaderVisible(false);
            }, 0)
        }
    }



    const handleCancel = () => {
        onClose()
    }
    return (
        <Form {...form}>

            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-8'>

                <div className="flex items-center gap-x-1">
                    <div className="basis-6/12">


                        <FormField

                            control={form.control}
                            name="customerName"
                            rules={{ required: true }}

                            render={({ field }) => (
                                <FormItem className="">
                                    <FormLabel>Customer Name</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            placeholder="Enter Customer Name"
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                    </div>

                    <div className="basis-6/12">
                        <FormField

                            control={form.control}
                            name="phone"
                            rules={{ required: true }}

                            render={({ field }) => (
                                <FormItem className="space-x-1">
                                    <FormLabel>Customer Phone</FormLabel>
                                    <FormControl>
                                        <Input
                                            {...field}
                                            type="tel"
                                            placeholder="Enter Customer Phone"

                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                <div className="flex items-center gap-x-1">
                    <div className="basis-6/12">
                        <FormField
                            control={form.control}
                            name="pickUpDateAndTime"
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FormItem className="space-x-1">
                                    <FormLabel>Pickup Date and Time</FormLabel>
                                    <FormControl>
                                        <DateTimePicker fieldProps={field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                    </div>
                </div>



                <div className="">
                    <FormField
                        control={form.control}
                        name="pickUpAddress"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <FormItem className="space-x-1">
                                <FormLabel>Pickup Address</FormLabel>
                                <FormControl>
                                    <Textarea placeholder='Enter Pickup Address' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>

                <DialogFooter>


                    <DialogClose asChild>

                        <Button variant="destructive" className="mt-4 px-4 py-2" onClick={handleCancel}>
                            Close
                        </Button>

                    </DialogClose>

                    <Button className='mt-4 px-4 py-2' type="submit">
                        Submit
                    </Button>
                </DialogFooter>

            </form>
        </Form >
    )
}



export default UpdateRideDetailsForm;