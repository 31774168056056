/* eslint-disable @typescript-eslint/unbound-method */

import { UserData } from "@/typings";
import { Session } from "@ory/kratos-client";
import { create } from "zustand";

/**
 * Type defs for Sidebar Store
 */
interface UserDataStore {
  user: UserData;
  sessionData: Session;
  setUser: (uData: Partial<UserData>) => void;
  setUserSessionData: (sData: Session) => void;
}

/**
 * Custom hook created with zustand
 */
export const useUserStore = create<UserDataStore>((set) => ({
  user: {} as UserData,
  sessionData: {} as Session,
  setUser: (uData) => {
    set((state) => {
      return {
        ...state,
        user: { ...state.user, ...uData } as UserData,
      };
    });
  },
  setUserSessionData: (sData) => {
    set((state) => {
      return {
        ...state,
        sessionData: { ...sData },
      };
    });
  },
}));
