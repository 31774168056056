import { isUndefinedVal } from '@/lib/utils';
import React, { forwardRef } from 'react';
import { cn } from '@/lib/utils';


export type TextVariants =
    | 'c1'
    | 'c2'
    | 'c2-medium'
    | 'c2-semibold'
    | 'c2-bold'
    | 'h1'
    | 'h2'
    | 'h2-small'
    | 'h3'
    | 'h3-semibold'
    | 'body'
    | 'b1'
    | 'b1-medium'
    | 'b1-bold'
    | 'b1-semibold'
    | 'b2'
    | 'b2-medium'
    | 'body-bold'
    | 'body-small'
    | 'body-small-bold'
    | 's1'
    | 's1-medium'
    | 's1-semibold'
    | 'b2-bold'
    | 'h3-large-bold'
    | 'h3-large-medium'
    | 'h3-large'
    | 'h3-bold'
    | 'b2-semibold';
/**
 * List of all available heading variants
 */
const headingVariants = ['c1', 'h1', 'h2', 'h2-small', 'h3'];

/**
 * List of variants which require line-height
 */
const variantsWithLineHeights = ['h2', 'h2-small'];
/**
 * Returns the html tag based on the provided variant
 */
const getTagVariant = (variant: TextVariants): React.ElementType => {
    let Tag: unknown = 'p';

    const isVariantExists = !isUndefinedVal(variant);

    if (isVariantExists && headingVariants.includes(variant)) {
        switch (variant) {
            case 'h1':
            case 'c1':
                Tag = 'h1';
                break;
            case 'h2':
            case 'h2-small':
                Tag = 'h2';
                break;
            case 'h3':
            case 'h3-semibold':
                Tag = 'h3';
                break;
        }
    }

    return Tag as React.ElementType;
};

/**
 * Type definitions for the props of Typography
 */
export interface TypographyProps {
    /**
     * Variant of text
     */
    variant?: TextVariants;
    /**
     * Text node
     */
    children: React.ReactNode;
    /**
     * HTML tag
     */
    tag?: string;
    /**
     * Custom css for the tag
     */
    className?: string;
    onClick?: VoidFunction;
}

/**
 * Component which displays the text with pre-config
 * styling or with custom styling for the provided variant
 */
const Typography = forwardRef(
    (
        { variant = 'b1', children, className, tag, onClick }: TypographyProps,
        ref
    ) => {
        const Tag = tag ? tag : getTagVariant(variant);
        const clubbbedUpClassNames = cn("font-figtree", {
            'text-[3.5rem] font-medium': variant === 'c1',
            'text-3xl': variant === 'c2',
            'text-3xl font-medium': variant === 'c2-medium',
            'text-3xl font-semibold': variant === 'c2-semibold',
            'text-3xl font-bold': variant === 'c2-bold',
            'text-[2rem] font-medium': variant === 'h1',
            'text-[1.625rem] font-medium leading-[1.5]': variant === 'h2',
            'text-2xl font-medium leading-[1.5]': variant === 'h2-small',
            'text-lg font-medium': variant === 'h3',
            'text-lg font-semibold': variant === 'h3-semibold',
            'text-lg font-bold': variant === 'h3-bold',

            'text-xl font-normal': variant === 'h3-large',
            'text-xl font-medium': variant === 'h3-large-medium',
            'text-xl font-bold': variant === 'h3-large-bold',
            'text-base font-normal': variant === 'b1',
            'text-base font-semibold': variant === 'b1-semibold',
            'text-base font-medium': variant === 'b1-medium',
            'text-base font-bold': variant === 'b1-bold',
            'text-sm font-normal ': variant === 'body' || variant === 'b2',
            'text-sm font-bold': variant === 'b2-bold',
            'text-sm font-medium': variant === 'b2-medium',
            'text-sm font-semibold': variant === 'b2-semibold',
            'text-xs font-normal': variant === 's1',
            'text-xs font-medium': variant === 's1-medium',
            'text-xs font-semibold': variant === 's1-semibold',
            'text-[0.625rem] font-normal': variant === 'body-small',
            'text-[0.625rem] font-bold': variant === 'body-small-bold',
            'leading-none': variantsWithLineHeights.includes(variant),
            [className!]: !!className,
        });
        return (
            <Tag ref={ref} className={clubbbedUpClassNames} onClick={onClick}>
                {children}
            </Tag>
        );
    }
);

Typography.displayName = 'Typography';

export default Typography;
