/* eslint-disable @typescript-eslint/no-misused-promises */


import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogClose } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'

import { zodResolver } from '@hookform/resolvers/zod'
import { createUserSettingsFlow, submitUserSettingsFlowData } from "@/lib/helpers/user";
import { extractCsrfTokenFromOryResponse, extractErrorMessageFromOryResponse } from "@/lib/ory";
import { RawUpdateUserSettingsData } from "@/typings";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { AxiosError } from "axios";
import { SettingsFlow } from "@ory/kratos-client";
import { showErrorToast, showSuccessToast } from "@/lib/helpers/toast";
import { useEffect } from "react";
import { useGlobalLoaderStore } from "@/lib/hooks/use-global-loader";
import { PasswordInput } from "./custom/password-input";


/**
 * Schema for change password schema
 */
const changePasswordSchema = z.object({
    password: z
        .string()
        .min(8, {
            message: 'Password must be at least 8 characters long ',
        }),
    confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, {
    path: ['confirmPassword'],
    message: 'Passwords do not match',
});

type ChangePasswordFormValues = z.infer<typeof changePasswordSchema>

// This can come from your database or API.
const defaultValues: Partial<ChangePasswordFormValues> = {
    password: "",
    confirmPassword: "",


}

interface ChangePasswordDialogProps {
    isOpen: boolean,
    onOpenChange: (isOpen: boolean) => void,
    onSuccess?: () => void
}

/**
 * Component which shows dialog for changing password
 */
const ChangePasswordDialog = ({ isOpen, onOpenChange }: ChangePasswordDialogProps) => {


    const { isVisible: isLoaderVisible, setLoaderVisible } = useGlobalLoaderStore();

    /**
     * Setup form
     */
    const form = useForm<ChangePasswordFormValues>({
        resolver: zodResolver(changePasswordSchema),
        defaultValues,
        mode: 'onChange',
    })


    useEffect(() => {
        if (!isOpen) {
            form.reset(defaultValues);
        }
    }, [isOpen])

    /**
     * Submit password data to indentity service
     * @param data 
     */
    const onSubmit = async (data: ChangePasswordFormValues) => {

        try {


            setLoaderVisible(true);

            const settingsFlow = await createUserSettingsFlow()

            const passwordUpdateData = {
                csrfToken: extractCsrfTokenFromOryResponse(settingsFlow),
                password: data.password,
                method: "password"
            }

            await submitUserSettingsFlowData(passwordUpdateData as RawUpdateUserSettingsData, settingsFlow.id)
            showSuccessToast("Password has been changed")

            setTimeout(() => {
                onOpenChange(false);
            }, 100)


        } catch (error) {
            let errorMsg = "";
            if ((error as AxiosError).response?.status === 400) {
                const errorResponse = (error as AxiosError).response?.data as SettingsFlow;
                errorMsg = extractErrorMessageFromOryResponse(errorResponse);
            }


            if (!errorMsg && (error as AxiosError).response?.status === 403) {
                errorMsg = "Please re-login to make changes!";
            }

            showErrorToast(errorMsg || DEFAULT_ERROR_MESSAGE)
        } finally {
            setLoaderVisible(false);
        }
    }



    const handlePasswordDialogStateChange = (isOpen: boolean) => {
        if (isLoaderVisible) return;
        onOpenChange(isOpen)
    }

    return (

        <Dialog open={isOpen} onOpenChange={handlePasswordDialogStateChange}>
            <DialogContent>
                <DialogTitle>Change your password</DialogTitle>
                <Form {...form}>

                    <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>





                        <FormField
                            control={form.control}
                            name='password'
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>New Password</FormLabel>
                                    <FormControl>
                                        <PasswordInput autoComplete="new-password" placeholder='Enter your new password' {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='confirmPassword'
                            rules={{ required: true }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Confirm New Password</FormLabel>
                                    <FormControl>
                                        <PasswordInput autoComplete="new-password" placeholder='Re-enter your new password' {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />






                        <DialogFooter>


                            <DialogClose asChild>

                                <Button variant="destructive" className="mt-4 px-4 py-2">
                                    Close
                                </Button>

                            </DialogClose>

                            <Button className='mt-4 px-4 py-2' type="submit">
                                Submit
                            </Button>
                        </DialogFooter>

                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}


export default ChangePasswordDialog;