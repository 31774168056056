import UserProfileSettingsView from "@/components/user-profile-settings-view";


/**
 * Component which shows user profile view in settings view
 */
const UserProfileView = () => {
    return (
        <UserProfileSettingsView />
    )
}


export default UserProfileView;