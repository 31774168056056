import http from "@/lib/http";
import Storage from "@/lib/storage";
import {
  GenericReqResponse,
  GetRidesListResponse,
  GetRidesQueryParams,
  RideDataItem,
  RideItemList,
  RideStatus,
  UpdatableRideData,
  UploadRidesResponse,
} from "@/typings";
import {
  RIDE_STATUS_CONFIG,
  RIDES_LAST_SYNC_KEY_NAME,
  WAIT_TIME_FOR_CALL_RESPONSE,
} from "@/variables/rides";
import { DATE_INTERNAL_FORMAT_IN_USE } from "@/variables/dates";
import { formatDate, getCurrentTimeInUTC, getDateFromISO } from "./datetime";
import { AxiosResponse } from "axios";
import { isEmpty } from "lodash";

/**
 * Upload csv file to update or insert rides data
 * @param fileData CSV File meta data
 * @param method Method which can be insert or update
 */
export const uploadRidesFile = async (
  fileData: File,
  method: "update" | "insert" = "insert"
) => {
  const formData = new FormData();
  formData.append("file", fileData);
  const res = await http.post(
    method === "insert" ? "/rides" : "/rides/bulk/update",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res.data as UploadRidesResponse;
};

export const getRidesList = async (
  queryParams: Partial<GetRidesQueryParams> = {}
) => {
  let endDate: string;
  let startDate: string;
  let rideStatus: string;
  let rideTypeToUse: string;
  if (queryParams.endDate) {
    endDate = formatDate(
      queryParams.endDate,
      DATE_INTERNAL_FORMAT_IN_USE,
      true
    );
  }

  if (queryParams.startDate) {
    startDate = formatDate(
      queryParams.startDate,
      DATE_INTERNAL_FORMAT_IN_USE,
      true
    );
  }

  if (queryParams.status) {
    rideStatus =
      queryParams.status === RIDE_STATUS_CONFIG.NO_ANSWER
        ? RIDE_STATUS_CONFIG.IN_PROGRESS
        : queryParams.status;
  }

  if (queryParams.type?.trim()) {
    rideTypeToUse = queryParams.type?.trim().toLowerCase();
  }
  const res = await http.get("/rides", {
    params: {
      ...queryParams,
      status: rideStatus!,
      endDate: endDate!,
      startDate: startDate!,
      type: rideTypeToUse!,
    },
  });

  return res.data as GetRidesListResponse;
};

export const callCustomerForConfirmation = async (
  rideIds: string | string[]
) => {
  rideIds = !Array.isArray(rideIds) ? [rideIds] : rideIds;

  if (isEmpty(rideIds)) {
    throw new Error("Invalid list of ride ids");
  }

  let res: AxiosResponse<GenericReqResponse>;

  if (rideIds.length === 1) {
    res = await http.post(`/rides/${rideIds[0]}/init-confirmation-call`);
  } else {
    res = await http.post("/rides/initiate-confirmation-calls", {
      rideIds,
    });
  }

  return res.data;
};

export const changeStatusOfRide = async (
  rideId: string,
  status: RideStatus
) => {
  const res = await http.patch(`/rides/${rideId}/status`, {
    rideStatus: status,
  });
  return res.data as GenericReqResponse;
};

export const storeLastSyncOfRides = (lastSync: string) => {
  Storage.set(RIDES_LAST_SYNC_KEY_NAME, lastSync);
};

export const getStoredLastSyncOfRides = () => {
  return Storage.get(RIDES_LAST_SYNC_KEY_NAME) as string;
};

export const getUpdatedRidesList = async (lastUpdatedAt: string) => {
  const res = await http.get("/rides", {
    params: {
      lastUpdatedAt,
    },
  });

  const { list } = res.data as { list: RideItemList };

  return list;
};

export const changeStatusOfManyRides = async (
  rideIds: string[],
  status: RideStatus
) => {
  const res = await http.patch(`/rides/bulk/status-update`, {
    rideIds,
    rideStatus: status,
  });
  return res.data as GenericReqResponse;
};

/**
 * This function sends the ride ids which needs to be removed
 * @param rides List of rides to remove
 */
export const removeRides = async (rides: RideItemList) => {
  const res = await http.delete(`/rides/bulk/delete`, {
    data: {
      rideIds: rides.map((r) => r._id),
    },
  });
  return res.data as GenericReqResponse;
};

/**
 * This function gets the status of the provided ride data
 * @param rideData Data of ride which will be used to get status
 */
export const getRideStatusFromRideData = (rideData: RideDataItem) => {
  const providedRideStatus = rideData.status;
  const callStartedAt = rideData.callStartedAt;
  let rideStatusToUse = rideData.status;
  if (providedRideStatus === RIDE_STATUS_CONFIG.IN_PROGRESS && callStartedAt) {
    const currTime = getCurrentTimeInUTC();
    const convertedTimeStamp = getDateFromISO(callStartedAt as string).toUTC();
    const diff = Math.round(
      currTime.diff(convertedTimeStamp, "minutes").minutes
    );
    if (diff > WAIT_TIME_FOR_CALL_RESPONSE) {
      rideStatusToUse = RIDE_STATUS_CONFIG.NO_ANSWER;
    }
  }
  return rideStatusToUse;
};

/**
 * This function sends the updated ride data and ride to backend for update
 * @param rideId Id of ride to update
 * @param rideData Updated ride data
 */
export const updateRideDetails = async (
  rideId: string,
  rideData: UpdatableRideData
) => {
  const res = await http.put(`/rides/${rideId}/update`, {
    ...rideData,
  });

  return res.data as AxiosResponse<GenericReqResponse>;
};
