


/**
 * Type defs for ViewHeading props
 */
interface ViewHeading {
    /**
     * Title for the view
     */
    title: string,
    /**
     * Subtitle for the view
     */
    subtitle?: string
}

/**
 * Component which shows heading for the view
 */
const ViewHeading = ({
    title,
    subtitle
}: ViewHeading) => {
    return (

        <div>
            <h2 className='text-2xl font-bold tracking-tight'>{title}</h2>
            {
                subtitle &&
                <p className='text-muted-foreground'>
                    {subtitle}
                </p>
            }

        </div>
    )
}


export default ViewHeading