import { Settings, DateTime } from "luxon";

/**
 * Change default time
 */
Settings.defaultZone = import.meta.env.VITE_APP_TIMEZONE;

/**
 *
 * @param date Date to format
 * @param format Format
 * @param useLocalZone Use system tiemzone
 */
export const formatDate = (
  date: Date,
  format: string,
  useLocalZone?: boolean
) => {
  const zoneOptions = {
    zone: "local",
  };
  return DateTime.fromJSDate(
    date,
    useLocalZone ? zoneOptions : undefined
  ).toFormat(format);
};

/**
 * Converts provided ISO timestamp into valid date
 * @param timestamp ISO timestamp to convert into date
 */
export const getDateFromISO = (timestamp: string) => {
  return DateTime.fromISO(timestamp);
};
/**
 * This function returns current time in UTC
 */
export const getCurrentTimeInUTC = () => DateTime.now().toUTC();

/**
 * Convert ISO date string into a valid JS date object or luxon object
 *
 * By default it will return luxon date object
 * @param dateStr ISO date string
 * @param returnAsJsDate Return object as JS date instead of luxon date object
 */
export const getDateFromISOWithLocalZone = (
  dateStr: string,
  returnAsJsDate?: boolean
) => {
  const convertedDate = DateTime.fromISO(dateStr).setZone("local", {
    keepLocalTime: true,
  });

  if (returnAsJsDate) {
    return convertedDate.toJSDate();
  }
  return convertedDate;
};

/**
 * Convert JS date object into a luxon date object
 * @param date JS date object
 */
export const convertDateFromJsDate = (date: Date) => {
  return DateTime.fromJSDate(date).setZone("local");
};
// Exported DateTime object from luxon
export default DateTime;
