
import SettingsDashboard from "@/components/settings-dashboard";
import { Separator } from "@/components/ui/separator";
import ViewHeading from "@/components/view-heading";

/**
 * Component which shows settings view
 */
const SettingsView = () => {
    return (
        <>
            <div className="flex size-full flex-col">

                <div className="mb-2">

                    <ViewHeading title="Settings" subtitle="Manage your settings here" />

                </div>

                <Separator className='my-4 lg:my-6' />

                <div className="size-full">
                    <SettingsDashboard />
                </div>



            </div>


        </>


    )
}

export default SettingsView;