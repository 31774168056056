/* eslint-disable @typescript-eslint/no-misused-promises */

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { showErrorToast } from '@/lib/helpers/toast';
import { combineUserName, createUserLogoutFlow, logoutUser } from '@/lib/helpers/user';
import { useGlobalLoaderStore } from '@/lib/hooks/use-global-loader';
import { useUserStore } from '@/lib/hooks/use-user';
import { extractErrorResponseFromHTTPRequest } from '@/lib/http';
import { GenericReqResponse } from '@/typings';
import { DEFAULT_ERROR_MESSAGE } from '@/variables/messages';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';


/**
 * Component which shows user avatar and provides some other options in a popup menu
 * 
 * It is used in the header
 */
const UserNav = () => {

    const navigate = useNavigate()

    const { user } = useUserStore()
    const setLoaderVisible = useGlobalLoaderStore((state) => state.setLoaderVisible)


    const combinedUserName = combineUserName({
        firstName: user.firstName,
        lastName: user.lastName
    })

    const handleLogout = async () => {


        setLoaderVisible(true);
        try {
            const logoutFlow = await createUserLogoutFlow();
            await logoutUser(logoutFlow.logout_token);
            navigate("/login", { replace: true })
        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResonse = extractErrorResponseFromHTTPRequest<GenericReqResponse>(error as AxiosError);
            if (errorResonse) {
                errorMsg = errorResonse.message!;
            }

            showErrorToast(errorMsg)
        } finally {
            setLoaderVisible(false)
        }
    }
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                    <Avatar className="h-8 w-8">
                        <AvatarImage
                            src={''}
                            alt={combinedUserName ?? ''}
                        />
                        <AvatarFallback>{combinedUserName?.[0]}</AvatarFallback>
                    </Avatar>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                    <div className="flex flex-col space-y-1">
                        <p className="text-sm font-medium leading-none">
                            {combinedUserName}
                        </p>
                        <p className="truncate text-xs leading-none text-muted-foreground">
                            {user?.email}

                        </p>
                    </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onSelect={() => navigate("/settings")}>
                    Settings

                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleLogout}>
                    Log out
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );

}



export {
    UserNav
}