/* eslint-disable @typescript-eslint/no-unused-vars */



import { useSidebar } from "@/lib/hooks/use-sidebar";
import { cn } from '@/lib/utils';
import { Dispatch, SetStateAction } from 'react';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from './ui/tooltip';
import { Link } from 'react-router-dom';
import { NavItem } from "@/typings";
import useCheckActiveNav from "@/lib/hooks/use-check-active-nav";
interface DashboardNavProps {
    items: NavItem[];
    setOpen?: Dispatch<SetStateAction<boolean>>;
    isMobileNav?: boolean;
}

/**
 * Component whicn shows navigations option in sidebar
 */
export function DashboardNav({
    items,
    setOpen,
    isMobileNav = false
}: DashboardNavProps) {
    const { checkActiveNav } = useCheckActiveNav()


    const { isMinimized } = useSidebar();

    if (!items?.length) {
        return null;
    }

    return (
        <nav className="grid items-start gap-2">
            <TooltipProvider>


                {items.map((item, index) => {
                    const isRouteActive = checkActiveNav(item.path!)
                    return (
                        item.path && (
                            <Tooltip key={index}>
                                <TooltipTrigger asChild>
                                    <Link
                                        to={item.disabled ? '/' : item.path}
                                        className={cn(
                                            'flex items-center gap-2 overflow-hidden rounded-md py-2 text-sm font-medium hover:bg-accent hover:text-accent-foreground',
                                            isRouteActive ? 'bg-accent text-primary hover:text-primary' : 'transparent',
                                            item.disabled && 'cursor-not-allowed opacity-80'
                                        )}
                                        onClick={() => {
                                            if (setOpen) setOpen(false);
                                        }}
                                    >

                                        {item.icon &&

                                            <div className={cn("ml-3 flex-none")}>
                                                {item.icon}
                                            </div>
                                        }


                                        {isMobileNav || (!isMinimized && !isMobileNav) ? (
                                            <span className={cn("mr-2 truncate")}>{item.title}</span>
                                        ) : (
                                            ''
                                        )}
                                    </Link>
                                </TooltipTrigger>
                                <TooltipContent
                                    align="center"
                                    side="right"
                                    sideOffset={8}
                                    className={!isMinimized ? 'hidden' : 'inline-block'}
                                >
                                    {item.title}
                                </TooltipContent>
                            </Tooltip>
                        )
                    );
                })}


            </TooltipProvider>
        </nav>
    );
}
