import { GenericReqResponse, RideItemList } from "@/typings";
import { useGlobalLoaderStore } from "./use-global-loader";
import { showSweetAlert } from "../helpers/sweet-alerts";
import { showErrorToast, showSuccessToast } from "../helpers/toast";
import { extractErrorResponseFromHTTPRequest } from "../http";
import { AxiosError } from "axios";
import { DEFAULT_ERROR_MESSAGE } from "@/variables/messages";
import { removeRides } from "../helpers/rides";

/**
 * Type defs for useRideActions hook
 */
interface UseRideActionsParams {
    onDeleteSuccess?: (rides: RideItemList) => void | Promise<void>;
}


/**
 * Custom hook which provides available actions for rides
 * @param params Options for hook
 */
const useRideActions = (params: UseRideActionsParams = {}) => {
    const { onDeleteSuccess } = params;
    const setLoaderVisible = useGlobalLoaderStore((state) => state.setLoaderVisible);

    /**
     * Helper function to remove the provided list of rides
     * @param rides List of rides to delete
     * @param mutliple  Its signifies if the list has one item or multiple and by default its true
     *
     */
    const handleRidesDelete = async (rides: RideItemList, mutliple = true) => {
        try {
            const response = await showSweetAlert({
                icon: "question",
                title: "Are you sure?",
                html: <div>
                    <p className="mb-2 text-lg ">
                        You want to remove the
                        {mutliple ? " selected rides?" : " selected ride?"}
                    </p>
                    <strong >This action cannot be undone</strong>
                </div>,
                showCancelButton: true,
                showConfirmButton: true,

            });


            if (!response.isConfirmed) {
                return;
            }

            setLoaderVisible(true);
            await removeRides(rides);
            onDeleteSuccess?.(rides)
            showSuccessToast(mutliple ? "Rides removed" : "Ride removed")

        } catch (error) {
            let errorMsg = DEFAULT_ERROR_MESSAGE;
            const errorResponseData = extractErrorResponseFromHTTPRequest(error as AxiosError<GenericReqResponse>);

            if (errorResponseData.message) {
                errorMsg = errorResponseData.message
            }

            showErrorToast(errorMsg);
        } finally {
            setLoaderVisible(false);
        }
    }


    return {
        removeRides: handleRidesDelete
    }

};


export default useRideActions