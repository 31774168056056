import { NavItem } from "@/typings";
import { CarIcon, SettingsIcon, ShieldCheckIcon, UserPenIcon } from "lucide-react"


/**
 * List of root navigations 
 */
export const navItems: NavItem[] = [
  {
    title: "Rides",
    path: "/",
    icon: <CarIcon />,
    label: "Rides",
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <SettingsIcon />,
    label: "profile",
  },
];




/**
 * List of navigations for settings view
 */
export const settingsNavItems = [
  {
    title: 'Profile',
    icon: <UserPenIcon size={18} />,
    path: '/settings',
  },
  {
    title: 'Security',
    icon: <ShieldCheckIcon size={18} />,
    path: '/settings/security',
  },

]
