import Header from "@/components/header"
import Sidebar from "@/components/sidebar"
import { extractUserDataFromSessionData } from "@/lib/helpers/user"
import { useUserStore } from "@/lib/hooks/use-user"
import { Session } from "@ory/kratos-client"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { Outlet, useLoaderData } from "react-router-dom"


/**
 * Component which is used to show dashboard in index route
 */
const DashboardView = () => {
    const { isUserLoggedIn, userSessionData } = useLoaderData() as { isUserLoggedIn: boolean, userSessionData: Session }
    const storedUserData = useUserStore((state) => state.user);
    const { setUser, setUserSessionData } = useUserStore()
    useEffect(() => {
        if (isUserLoggedIn && !isEmpty(userSessionData) && isEmpty(storedUserData)) {
            setUser(extractUserDataFromSessionData(userSessionData));
            setUserSessionData(userSessionData)
        }
    }, [])


    return (
        <div className="flex size-full min-w-0">


            <Sidebar />
            <main className="w-full flex-1 overflow-hidden">

                <div className="flex size-full flex-col ">
                    <Header />
                    <div className="min-h-0 min-w-0 flex-1 p-4">
                        <Outlet />
                    </div>

                </div>






            </main>
        </div>
    )
}


export default DashboardView