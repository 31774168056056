import { create } from "zustand";

/**
 * Type defs for Global loader
 */
interface GlobaloaderStore {
  isVisible: boolean;
  setLoaderVisible: (isVisible: boolean) => void;
}

/**
 * Custom hook for global loader created with zustand
 */
export const useGlobalLoaderStore = create<GlobaloaderStore>((set) => ({
  isVisible: false,
  setLoaderVisible: (val) => {
    set((state) => {
      return {
        ...state,
        isVisible: val,
      };
    });
  },
}));
